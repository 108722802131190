import apisauce from 'apisauce';
import { BASE_URL, TIMEOUT } from './constants';

export default class SettlementService {

    static async settlements() {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });
        const res = await api.get('/get_settlement_names');

        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            throw new Error(res.originalError.message);
        }

    }

    static async getSettlement(id, token) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+token.token);

        console.log('API Call');
        const res = await api.get('/settlement/' + id);

        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            console.log(res.originalError);
            throw new Error(res.originalError.message);
        }
    }

    static async getPending(token) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+token.token);

        console.log('API Call');
        const res = await api.get('/settlementpending');

        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            console.log(res.originalError);
            throw new Error(res.originalError.message);
        }
    }

    static async getRejected(token) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+token.token);

        console.log('API Call');
        const res = await api.get('/settlementreject');

        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            console.log(res.originalError);
            throw new Error(res.originalError.message);
        }
    }

    static async approve(id, json, token) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+token.token);

        console.log('API Call');
        const res = await api.put('/approve_settlement/' + id, json);
        if (res.ok) {
            return { status: res.status, message: res.data }
        } else {
            console.log(res.originalError);
            throw new Error(res.originalError.message);
        }

    }

    static async post(json) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        const res = await api.post('/settlement_registration', json);
        if (res.ok) {
            return { status: res.status, message: res.data }
        } else {
            throw new Error(res.data.error);
        }

    }

    static async getSettlementdReport(values, token){
        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+token.token);
        
        console.log('API Call');
        const res = await api.get('/settlement_report/?status='+values.status+'&division='+values.division+'&tenure='+values.tenure+'&category='+values.category);

        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            console.log(res.originalError);
            throw new Error(res.originalError.message);
        }
    }
}