import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import App from './App';

const title = 'React with Webpack and Babel';

ReactDOM.render(
  <React.StrictMode>
  <CookiesProvider>
    <App />
  </CookiesProvider>   
  </React.StrictMode>,
  document.getElementById('root'),
);
if (module.hot){
  module.hot.accept();
}
