import * as React from 'react'
import { useHistory, Link } from "react-router-dom";
import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
} from 'tabler-react'

import { SiteWrapper } from '../../components/SiteWrapper'
import SettlementService from '../../service/settlement';
import { useCookies } from 'react-cookie';


export const SettlementRejectedPage = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false);
  const [cookies, setCookie] = useCookies(['token']);
  const [tableData, setTableData] = React.useState([]);

  const makeList = (list) => {

    let arr = [];

    for (let i = 0; i < list.length; i++) {

      let element = {
        key: list[i].id,
        item: [
          { content: list[i].name },
          { content: list[i].division },
          { content: list[i].category },
          { content: list[i].first_name + ' ' + list[i].last_name },
          { content: list[i].verification },
          { content: list[i].remark },
          { content: <Link to={'/settlementapprove?id=' + list[i].id}> <Icon name="edit" /></Link> }
        ],
      };

      arr.push(element);
    }

    setTableData(arr);
  };

  const fetchData = async () => {

    setLoading(true);
    setError(false);
    
    try {

      let res = await SettlementService.getRejected(cookies);

      makeList(res.data);

    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>
        {loading ? (<Alert type="primary" hasExtraSpace>
          <strong>Loading... Please wait.</strong>
        </Alert>) : null}
        {error ? (<Alert type="danger" hasExtraSpace>
          <strong>Error: </strong> {error}.
        </Alert>) : null}
        <Dimmer active={loading} loader>
          <Grid.Row cards={true}>
            <Grid.Col width={12}>
              <Card title="Rejected Registrations">

                <Table
                  responsive
                  className="card-table table-vcenter text-nowrap"
                  headerItems={[
                    { content: 'Settlement Name' },
                    { content: 'Division' },
                    { content: 'Category' },
                    { content: 'Registant Name' },
                    { content: 'Verification' },
                    { content: 'Remarks' },
                    { content: null },
                  ]}
                  bodyItems={tableData}
                />

              </Card>
            </Grid.Col>
          </Grid.Row>
        </Dimmer>
      </Page.Content>
    </SiteWrapper>
  )
}
