import * as React from 'react'

import { useHistory, Redirect } from "react-router-dom";


import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
} from 'tabler-react'

import { SiteWrapper } from '../components/SiteWrapper'

import SettlementService from '../service/settlement';
import { useCookies, Cookies } from 'react-cookie';


export const HomePage = (props) => {

  const history = useHistory()

  const [cookie, setCookie] = useCookies(['token']);
  const [isAuthed, setIsAuthed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [pendingSettlements, setPendingSettlements] = React.useState([]);
  const [pendingHouseholds, setPendingHouseholds] = React.useState([]);

  const makeSettlementList = (list) => {

    list.length

    let arr = [];

    for (let i = 0; i < list.length; i++) {

      let element = {
        key: list[i].id,
        item: [
          { content: list[i].name },
          { content: list[i].division },
          { content: list[i].first_name + ' ' + list[i].last_name },
          { content: list[i].mobile_no },
          { content: list[i].email },
          { content: '2020-05-30' },
        ],
      };

      arr.push(element);
    }

    setPendingSettlements(arr);

  };

  const fetchData = async () => {

    setLoading(true);
    setError(false);

    // const [cookies, setCookie] = useCookies(['token']);

    try {

      let res = await SettlementService.getPending(cookie);

      makeSettlementList(res.data);


    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);


  return (
    <SiteWrapper>
      <Page.Content title="Dashboard">
        {/* Status Indicators */}

        {loading ? (<Alert type="primary" hasExtraSpace>
          <strong>Loading... Please wait.</strong>
        </Alert>) : null}
        {error ? (<Alert type="danger" hasExtraSpace>
          <strong>Error: </strong> {error}.
        </Alert>) : null}

        <Dimmer active={loading} loader>
          <Grid.Row cards={true}>
            <Grid.Col width={4} sm={3} lg={2}>
              <StatsCard
                layout={1}
                movement={0}
                total="43"
                label="New Registrations"
              />
            </Grid.Col>
            <Grid.Col width={4} sm={3} lg={2}>
              <StatsCard
                layout={1}
                movement={-3}
                total="17"
                label="Rejected Registrations"
              />
            </Grid.Col>
            <Grid.Col width={4} sm={3} lg={2}>
              <StatsCard
                layout={1}
                movement={9}
                total="7"
                label="Total Settlements"
              />
            </Grid.Col>
            <Grid.Col width={4} sm={3} lg={2}>
              <StatsCard
                layout={1}
                movement={3}
                total="27.3k"
                label="New Households"
              />
            </Grid.Col>
            <Grid.Col width={4} sm={3} lg={2}>
              <StatsCard
                layout={1}
                movement={-2}
                total="65k"
                label="Number of People"
              />
            </Grid.Col>
            <Grid.Col width={4} sm={3} lg={2}>
              <StatsCard layout={1} movement={-1} total="621" label="Chats" />
            </Grid.Col>
            <Grid.Col width={12}>
              <Card title="New Settlements">
                <Table
                  responsive
                  className="card-table table-vcenter text-nowrap"
                  headerItems={[
                    { content: 'Settlement Name' },
                    { content: 'Division' },
                    { content: 'Registant Name' },
                    { content: 'Mobile Number' },
                    { content: 'Email' },
                    { content: 'Submit Date' },
                  ]}
                  bodyItems={pendingSettlements}
                />
              </Card>
            </Grid.Col>
          </Grid.Row>

          {/* End of Status Indicator */}
        </Dimmer>
      </Page.Content>
    </SiteWrapper>
  )
}
