import * as React from 'react'
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useHistory
} from "react-router-dom";
import {
    Page,
    Avatar,
    Icon,
    Grid,
    Card,
    Text,
    Table,
    Alert,
    Progress,
    colors,
    Dropdown,
    Button,
    StampCard,
    StatsCard,
    ProgressCard,
    Badge,
    Loader,
    Dimmer,
    Content,
    Form,
    Container
} from 'tabler-react'


import { PublicSiteWrapper } from '../../components/PublicSiteWrapper'
import { EmployeementOptions, HouseTypeOptions } from '../Questions/Selections'

import { FacilitiesCheckboxes } from '../Questions/Checkboxes'
import ProfileService from '../../service/profile';
import { useCookies } from 'react-cookie';

export const ProfilePage = (props) => {

    const history = useHistory()

    const [profille, setProfile] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [cookies, setCookie] = useCookies(['pubtoken']);
    const fetchData = async () => {

        setLoading(true);
        setError(false);

        try {
            console.log(cookies);
            let res = await ProfileService.getsetProfile(cookies);

            setProfile(res.data);
            crispChat(res.data);
        } catch (error) {
            setLoading(false);
            setError(error.message);
            console.log(error);
        }
        setLoading(false);
    };

    const crispChat = (data)=>{
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "71362ca2-21e3-40f0-9e8f-5bf5c378ce19";
        window.$crisp.push(["set", "user:nickname", [data.tin_no]]);
        window.$crisp.push(["set", "user:email", [data.email]]);
        (() => {
            const d = document;
            const s = d.createElement("script");
            s.src = "https://client.crisp.chat/l.js";
            s.async = 1;
            d.getElementsByTagName("body")[0].appendChild(s);
        })();
    }

    React.useEffect(() => {
        fetchData();
        // crispChat();
    }, []);


    return (
        <PublicSiteWrapper logged>
            <Dimmer active={loading} loader>

                <div className="max-950 mx-auto">
                    <div className="my-3 my-md-5">
                        <Container>
                            <Card>
                                <Card.Body>
                                    <Card.Title>My Profile</Card.Title>
                                    <Grid.Row>
                                        <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                            <Form.Group
                                                className="mb-6 ml-4 mr-4"
                                                label="Settlement"
                                                isRequired
                                            >
                                                <Form.Input disabled value={profille.settlement_id} placeholder="" />
                                            </Form.Group>
                                        </Grid.Col>
                                    </Grid.Row>


                                    <Grid.Row>

                                        <Grid.Col md={2} lg={2}>
                                            <Form.Group className="mb-6 ml-4 mr-4" label="Title" isRequired>
                                                <Form.Select disabled value={profille.title}>
                                                    <option value="MS">Ms</option>
                                                    <option value="MR">Mr</option>
                                                    <option value="MI">Miss</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col md={12} lg={5}>
                                            <Form.Group
                                                className="mb-6 ml-4 mr-4"
                                                label="First Name"
                                                isRequired
                                            >
                                                <Form.Input disabled value={profille.first_name} placeholder="" />
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col  md={12} lg={5}>
                                            <Form.Group className="mb-6 ml-4 mr-4" label="Last Name">
                                                <Form.Input disabled value={profille.last_name} placeholder="" />
                                            </Form.Group>
                                        </Grid.Col>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                            <Form.Group className="mb-6 ml-4 mr-4" label="Date of Birth">

                                                <Form.Input disabled name="example-text-input" placeholder="" value="1950-05-01" />
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                            <Form.Group className="mb-6 ml-4 mr-4" label="Mobile Number">
                                                <Form.Input disabled value={profille.mobile_no} placeholder="78945612" />
                                            </Form.Group>
                                        </Grid.Col>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                            <Form.Group className="mb-6 ml-4 mr-4" label="Email">
                                                <Form.Input disabled
                                                            value={profille.email}
                                                            placeholder="abc@gmail.com"
                                                />
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                            <Form.Group className="mb-6 ml-4 mr-4" label="TIN Number">
                                                <Form.Input disabled value={profille.tin_no} placeholder="123456789" />
                                            </Form.Group>
                                        </Grid.Col>
                                    </Grid.Row>

                                    {/* <Grid.Row>
                                    <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                        <Form.Group className="mb-6 ml-4 mr-4" label="House type">
                                            <HouseTypeOptions disabled />
                                        </Form.Group>
                                    </Grid.Col>
                                </Grid.Row> */}
                                </Card.Body>
                                <Card.Footer className="text-right">
                                    Request via chat to change any information.
                                </Card.Footer>
                            </Card>
                        </Container>
                    </div>
                </div>
            </Dimmer>
        </PublicSiteWrapper>
    );
};