import * as React from 'react'

import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";

import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
  Form,
} from 'tabler-react'

import { PublicSiteWrapper } from '../../components/PublicSiteWrapper'

import { Formik, useFormik, useFormikContext } from "formik";
import * as yup from 'yup';

const validationSchema = yup.object({
    firstName:  yup.string().trim().required('First Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed fot this field"),
    lastName: yup.string().trim().required('Last Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed fot this field"),
    mobileNo: yup.string().trim().required('Mobile Number is required'),
    email: yup.string().trim().email('Must be a valid email').required('Email is required'),
    tinNo: yup.string().trim().required('TIN No is required'),
    photoId: yup.string().trim().required('Photo ID is required'),
    ConeFirstName:yup.string().trim().required('First Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed fot this field"),
    ConeLastName: yup.string().trim().required('Last Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed fot this field"),
    ConeMobileNo: yup.string().trim().required('Mobile is required'),
    ConeTinNo:yup.string().trim().required('TIN No is required'),
    CtwoFirstName: yup.string().trim().required('First Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed fot this field"),
    CtwoLastName:yup.string().trim().required('Last Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed fot this field"),
    CtwoMobileNo: yup.string().trim().required('Mobile Number is required'),
    CtwoTinNo: yup.string().trim().required('TIN No is required')
});

export const RegisterSettlementPage = (props) => {

  const {location} = props;

  console.log(location.state.model);



  const history = useHistory()
  const navigate = (url) => {
    history.push(url);
  }


const submit = (values) =>{

  console.log(values);

  let tmpModel = {...location.state.model};

  history.push('/settlementdetails', { model: { ...tmpModel, ...values } });

};


  const upload = (e) => {

    var reader = new FileReader();
    var file = e.target.files[0];

    reader.readAsDataURL(file);

    reader.onload = function () {

      formik.setFieldValue('photoId', reader.result, false);

    };

  };

  const formik = useFormik({
    initialValues: { firstName: '', lastName: '', mobileNo: '', email: '', tinNo: '', photoId: null, ConeFirstName: '', ConeLastName: '', ConeMobileNo: '', ConeTinNo: '', CtwoFirstName: '', CtwoLastName: '', CtwoMobileNo: '', CtwoTinNo: ''},
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: submit
  });

  return (
    <PublicSiteWrapper>
      <div className="max-950 mx-auto">
        <form onSubmit={formik.handleSubmit}>
          <Page.Card
            title="Register Settlement/Village"
            footer={
              <Card.Footer>
                <div className="d-flex">
                  <Button link>Cancel</Button>
                  <Button type="submit" color="primary" className="ml-auto">
                    Next
                </Button>
                </div>
              </Card.Footer>
            }
          >
            <h5>Registant Details</h5>
            <Grid.Row>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-4" label="First Name">
                  <Form.Input
                    name="firstName"
                    placeholder=""
                    value={formik.values.firstName}
                    onChange={formik.handleChange('firstName')}
                    invalid={formik.errors['firstName']}
                    feedback={formik.errors['firstName']}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-4" label="Last Name">
                  <Form.Input
                    name="lastName"
                    placeholder=""
                    value={formik.values.lastName}
                    onChange={formik.handleChange('lastName')}
                    invalid={formik.errors['lastName']}
                    feedback={formik.errors['lastName']}
                    />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-4" label="Mobile Number">
                  <Form.Input
                    name="mobileNo"
                    placeholder="8951325"
                    value={formik.values.mobileNo}
                    onChange={formik.handleChange('mobileNo')}
                    invalid={formik.errors['mobileNo']} 
                    feedback={formik.errors['mobileNo']}
                    maxLength={7}
                    />
                </Form.Group>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-4" label="Email">
                  <Form.Input
                    name="email"
                    placeholder="abc@gmail.com"
                    value={formik.values.email}
                    onChange={formik.handleChange('email')}
                    invalid={formik.errors['email']}
                    feedback={formik.errors['email']}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-4" label="TIN Number">
                  <Form.Input 
                    name="tinNo" 
                    placeholder="123456789" 
                    value={formik.values.tinNo}
                    onChange={formik.handleChange('tinNo')}
                    invalid={formik.errors['tinNo']}
                    feedback={formik.errors['tinNo']}
                    maxLength={9}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-4" label="Photo ID">
                  <Form.FileInput
                    name="photoId"
                    invalid={formik.errors['photoId']}
                    feedback={formik.errors['photoId']}
                    onChange={upload} />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <h5 className="mt-2">Committee Member One Details</h5>
            <Grid.Row>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-3" label="First Name">
                  <Form.Input 
                    name="ConeFirstName" placeholder=""
                    value={formik.values.ConeFirstName}
                    onChange={formik.handleChange('ConeFirstName')}
                    invalid={formik.errors['ConeFirstName']}
                    feedback={formik.errors['ConeFirstName']}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-3 mr-4" label="Last Name">
                  <Form.Input 
                    name="ConeLastName"
                    placeholder=""
                    value={formik.values.ConeLastName}
                    onChange={formik.handleChange('ConeLastName')}
                    invalid={formik.errors['ConeLastName']}
                    feedback={formik.errors['ConeLastName']}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-4" label="Mobile Number">
                  <Form.Input 
                    name="ConeMobileNo"
                    placeholder="8951325"
                    value={formik.values.ConeMobileNo}
                    onChange={formik.handleChange('ConeMobileNo')}
                    invalid={formik.errors['ConeMobileNo']}
                    feedback={formik.errors['ConeMobileNo']}
                    maxLength={7}
                    />
                </Form.Group>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-4" label="TIN Number">
                  <Form.Input 
                    name="ConeTinNo" 
                    placeholder="123456789" 
                    value={formik.values.ConeTinNo}
                    onChange={formik.handleChange('ConeTinNo')}
                    invalid={formik.errors['ConeTinNo']}
                    feedback={formik.errors['ConeTinNo']}
                    maxLength={9}
                    />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <h5 className="mt-2">Committee Member Two Details</h5>
            <Grid.Row>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-3" label="First Name">
                  <Form.Input 
                     name="CtwoFirstName" placeholder=""
                     value={formik.values.CtwoFirstName}
                     onChange={formik.handleChange('CtwoFirstName')}
                     invalid={formik.errors['CtwoFirstName']}
                     feedback={formik.errors['CtwoFirstName']}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-3 mr-4" label="Last Name">
                  <Form.Input 
                    name="CtwoLastName"
                    placeholder=""
                    value={formik.values.CtwoLastName}
                    onChange={formik.handleChange('CtwoLastName')}
                    invalid={formik.errors['CtwoLastName']}
                    feedback={formik.errors['CtwoLastName']}
                    />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-4" label="Mobile Number">
                  <Form.Input 
                    name="CtwoMobileNo"
                    placeholder="8951325"
                    value={formik.values.CtwoMobileNo}
                    onChange={formik.handleChange('CtwoMobileNo')}
                    invalid={formik.errors['CtwoMobileNo']}
                    feedback={formik.errors['CtwoMobileNo']}
                    maxLength={7}
                    />
                </Form.Group>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-4 ml-4 mr-4" label="TIN Number">
                  <Form.Input 
                    name="CtwoTinNo" 
                    placeholder="123456789" 
                    value={formik.values.CtwoTinNo}
                    onChange={formik.handleChange('CtwoTinNo')}
                    invalid={formik.errors['CtwoTinNo']}
                    feedback={formik.errors['CtwoTinNo']}
                    maxLength={9}
                    />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Page.Card>
        </form>
      </div>
    </PublicSiteWrapper>
  )
}
