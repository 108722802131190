import * as React from 'react';
import { useHistory, Link } from "react-router-dom";

import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
} from 'tabler-react'

import { SiteWrapper } from '../../components/SiteWrapper'
import HouseholdService from '../../service/household';
import { useCookies } from 'react-cookie';

export const HouseholdPendingPage = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false);

  const [tableData, setTableData] = React.useState([]);
  const [cookies, setCookie] = useCookies(['token']);
  const makeSettlementList = (list) => {

    let arr = [];

    for (let i = 0; i < list.length; i++) {

      let element = {
        key: list[i].id,
        item: [
          { content: list[i].settlement_id },
          { content: list[i].first_name },
          { content: list[i].last_name },
          { content: list[i].mobile_no },
          { content: list[i].email },
          { content: list[i].create_date },
          { content: <Link to={'/householdapprove?id=' + list[i].id}> <Icon name="edit" /></Link> }
        ],
      };

      arr.push(element);
    }

    setTableData(arr);
  };

  const fetchData = async () => {

    setLoading(true);
    setError(false);

    try {

      let res = await HouseholdService.getPending(cookies);

      makeSettlementList(res.data);


    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);



  return (
    <SiteWrapper>
      <Page.Content>

        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card title="New Household Registrations">

              {loading ? (<Alert type="primary" hasExtraSpace>
                <strong>Loading... Please wait.</strong>
              </Alert>) : null}
              {error ? (<Alert type="danger" hasExtraSpace>
                <strong>Error: </strong> {error}.
              </Alert>) : null}


              <Dimmer active={loading} loader>
                <Table
                  responsive
                  className="card-table table-vcenter text-nowrap"
                  headerItems={[{ content: 'Settlement Name' },
                  { content: 'First Name' },
                  { content: 'Last Name' },
                  { content: 'Mobile Number' },
                  { content: 'Email' },
                  { content: 'Submit Date' },
                  { content: null }]}
                  bodyItems={tableData}
                />

              </Dimmer>
            </Card>
          </Grid.Col>
        </Grid.Row>

      </Page.Content>
    </SiteWrapper>
  )
}
