import * as React from 'react'
import {
  Site,
  Nav,
  Grid,
  List,
  Button,
  RouterContextProvider,
} from 'tabler-react'

import { NavLink, Link, withRouter } from 'react-router-dom'

const navBarItems = [
  {
    value: 'Home',
    to: '/',
    icon: 'home',
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: 'Settlements',
    icon: 'box',
    subItems: [
      {
        value: 'Pending Approval',
        to: '/settlementpending',
        LinkComponent: withRouter(NavLink),
      },
      {
        value: 'Rejected',
        to: '/settlementrejected',
        LinkComponent: withRouter(NavLink),
      },
      {
        value: 'Settlement List',
        to: '/settlementlist',
        LinkComponent: withRouter(NavLink),
      },
    ],
  },
  {
    value: 'Household',
    icon: 'calendar',
    subItems: [
      { value: 'Maps', to: '/maps', LinkComponent: withRouter(NavLink) },
      { value: 'Icons', to: '/icons', LinkComponent: withRouter(NavLink) },
      { value: 'Store', to: '/store', LinkComponent: withRouter(NavLink) },
      { value: 'Blog', to: '/blog', LinkComponent: withRouter(NavLink) },
    ],
  },
  {
    value: 'Reporting',
    icon: 'calendar',
    subItems: [
      { value: 'Settlement Filtering', to: '/maps', LinkComponent: withRouter(NavLink) },
      { value: 'Household Filtering', to: '/icons', LinkComponent: withRouter(NavLink) },
    ],
  }
]
const loggedInUser = localStorage.getItem("username");
const accountDropdownProps = {
  avatarURL: './demo/faces/female/25.jpg',
  name: loggedInUser,
  description: loggedInUser,
  options: [
    { icon: 'settings', value: 'Change Password', to: "/changepassword" },
    { icon: 'log-out', value: 'Sign out', to: "/users" },
  ],
}



export const PublicSiteWrapper = (props) => {

  const { logged } = props;

  const { children, staticContext, ...SiteProps } = props

  return (
    <div className="background" >
      <Site.Wrapper
        {...SiteProps}
        headerProps={{
          href: '/',
          alt: 'Citizen Portal - MySettlement',
          imageURL: './images/logo.svg',

          accountDropdown: (logged ? accountDropdownProps : (null)),
        }}

        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          copyright: (
            <React.Fragment>
              Copyright © 2020
              <a
                href="https://mobile.digital.gov.fj/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {'  '}
                Ministry of Housing and Community Development - Fiji
                {'  '}
              </a>
              {'  '}All rights reserved.
            </React.Fragment>
          ),
        }}
      >
        {children}
      </Site.Wrapper>
    </div>
  )
}
