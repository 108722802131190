import * as React from 'react'

import { Formik, useFormik, useFormikContext } from "formik";


import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";

import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  List,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
  Form,
} from 'tabler-react'


import { PublicSiteWrapper } from '../../components/PublicSiteWrapper'

import * as yup from 'yup';

const validationSchema = yup.object({
  settlementName: yup.string()
    .trim()
    .required('Settlement Name is required')
    .min(2, 'Name must be atleast 2 characters')
    .max(50, 'Name is too long')
    .matches(/^[A-Za-z. 0-9]+$/, 'A proper Settlement Name should be given'),
});


const settlementList = [];
const filter = (item, query) => item.title.toLowerCase().includes(query.toLowerCase());

export const SettlementNamePage = (props) => {
  const history = useHistory();

  // Autocomplete.
  const [autoValue, setAutoValue] = React.useState('');
  const [autoData, setAutoData] = React.useState([]);


  const [nameOk, setNameOk] = React.useState(false);
  const [nameExist, setNameExist] = React.useState(false);
  const [committee, setCommittee] = React.useState(true);
  const [newCommittee, setNewCommittee] = React.useState(false);

  const navigate = (url) => {
    history.push(url);
  }

  const submit = (values) => {

    console.log("Submit");
    console.log(values);

    history.push('/registersettlement', { model: { ...values } });

  };

  const isCommittee = (value) => {

    if (value == "No") {
      formik.values.committeeMember = false;
      setCommittee(false);
    } else {
      formik.values.committeeMember = true;
      setCommittee(true);
    }
  };

  const becomeCommittee = (value) => {
    if (value == "No") {
      setNewCommittee(false);
    } else {
      setNewCommittee(true);
    }
  };

  const renderOption = (item, index) => (
    <List.Item key={index}>{item.title}</List.Item>
  );

  const textChange = (value) => {

    setNameExist(false);

    if (nameOk) {
      setNameOk(false);
    }

    if (formik.isDirty) {
      return;
    }

    setAutoValue(value);
    // Search optimization can be done here.

    if (value.trim() == '') {
      setAutoData([]);
    }
    else {
      setAutoData(settlementList.filter(item => filter(item, value)));
    }

    formik.setFieldValue('settlementName', value, true); // May be not needed.

  };

  const checkName = () => {

    //formik.values.settlementName

    setNameOk(true);
    setNameExist(false);

  };

  const formik = useFormik({
    initialValues: { settlementName: '', committeeMember: true },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: submit
  });

  return (
    <PublicSiteWrapper>
      <div className="max-950 mx-auto">

        <form onSubmit={formik.handleSubmit}>

          <Page.Card
            title="Register Settlement/Village"
            footer={
              <Card.Footer>
                <div className="d-flex">
                  <Button RootComponent="a" href="/">Back</Button>
                  <Button type="submit" disabled={!(nameOk && (newCommittee || committee))} color="primary" className="ml-auto">
                    Next
                </Button>
                </div>
              </Card.Footer>
            }
          >
            <Grid.Row>
              <Grid.Col md={12} lg={12}>

                <Form.Group className="mb-2 ml-4 mr-4" label="Settlement Name">
                  <Form.StaticText>Make sure your settlement is not registered already.
                Registered settlements are listed below as you type.</Form.StaticText>
                  <Grid.Row>
                    <Grid.Col md={12} lg={6}>
                      <Form.Input
                        name="settlementName"
                        placeholder="Check settlement name"
                        value={formik.values.settlementName}
                        onChange={(e) => textChange(e.currentTarget.value)}
                        invalid={formik.errors['settlementName'] ? true : (nameExist ? true : false)}
                        feedback={formik.errors['settlementName'] ? formik.errors['settlementName'] : (nameExist ? 'Name already exist' : '')}
                        tick={nameOk}
                      />
                      <>
                        <List unstyled>
                          {autoData.map(renderOption)}
                        </List>
                      </></Grid.Col>
                  </Grid.Row>

                </Form.Group>

              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col md={12} lg={6}>
                <Button type="button" color="primary" className="mb-6 ml-4" onClick={checkName}>
                  Check availability
              </Button>
              </Grid.Col>
            </Grid.Row>

            {nameOk ? (
              <Grid.Row>
                <Grid.Col md={12} lg={6}>

                  <Form.Group
                    className="mb-6 ml-4 mr-4"
                    label="Are you a member of a committee?"
                  >
                    <Form.Radio
                      label="Yes"
                      name="committeeMember"
                      onChange={(e) => { isCommittee(e.currentTarget.value) }}

                      value="Yes"
                    />
                    <Form.Radio
                      label="No"
                      name="committeeMember"
                      onFocus={formik.handleChange('committeeMember')}
                      onChange={(e) => { isCommittee(e.currentTarget.value) }}
                      value="No"
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>) : null}

            {(nameOk && committee == false) ? (
              <Grid.Row>
                <Grid.Col md={12} lg={6}>
                  <Form.Group
                    className="mb-6 ml-4 mr-4"
                    label="Do you wish to become a committee member?"
                  >
                    <Form.Radio
                      label="Yes"
                      name="becomeCommittee"
                      onChange={(e) => { becomeCommittee(e.currentTarget.value) }}
                      value="Yes"
                    />
                    <Form.Radio
                      label="No"
                      name="becomeCommittee"
                      onChange={(e) => { becomeCommittee(e.currentTarget.value) }}
                      value="No"
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>) : (null)}

            <Grid.Row>
              <Grid.Col md={12} lg={12}>
                <Form.Group
                  className="mb-6 ml-4 mr-4">
                  {(committee || newCommittee) ? (<Form.StaticText>Nominate Two(2) more members of your committee to complete the registration process.</Form.StaticText>
                  ) : null}

                  {(committee == false && newCommittee == false) ? (
                    <Form.StaticText >Sorry, you must be a committee member of your settlement to register.</Form.StaticText>
                  ) : (null)}
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

          </Page.Card>
        </form>
      </div>
    </PublicSiteWrapper>
  )
}
