import React from 'react';
import {
    useHistory,
    Link,
} from "react-router-dom";

import {Form, Card, Button, Alert} from 'tabler-react';

import { Formik, useFormik, useFormikContext } from "formik";
import * as yup from 'yup';

import '../../styles/style.css';
import '../../styles/helper.css';

import Logo from '../../images/logo.svg';
import AuthenticationService from "../../service/authentication";
import {useCookies} from "react-cookie";

const validationSchema = yup.object({
    username: yup.string()
        .trim()
        .required('TIN Number is required'),
    password: yup.string()
        .trim()
        .required('Password cannot be blank')
});


export const PublicLoginPage = () => {

    const history = useHistory();

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [error, setError] = React.useState(false);

    const submit = async (values) =>{
        try {

            let res = await AuthenticationService.login(values.username, values.password);
            let token = res.data.token;

            setCookie('pubtoken', token, { path: '/' });
            localStorage.setItem('username', values.username)
            history.push('/profile');

        } catch (error) {

            setError(true);
        }

        history.replace('/profile', { token: token });

        // console.log(values);
        // history.push('/profile');
    };
    const formik = useFormik({
        initialValues: { username: '', password: '' },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: submit,
    });

    const crispChat = ()=>{
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "71362ca2-21e3-40f0-9e8f-5bf5c378ce19";
        (() => {
            const d = document;
            const s = d.createElement("script");
            s.src = "https://client.crisp.chat/l.js";
            s.async = 1;
            d.getElementsByTagName("body")[0].appendChild(s);
        })();
    }

    React.useEffect(() => {
        removeCookie('token');
        crispChat();
        localStorage.clear();
    }, []);

    return (
        <div className="vh-100 background">
            <div className="page">
                <div className="page-single">
                    <div className="container">
                        <div className="row">
                            <div className="col col-login mx-auto">
                                <div className="text-center mb-6">
                                    <img src={Logo} className="h-8" alt="logo" />
                                    <h4 className="mt-4">MySettlement</h4>
                                </div>
                                <Form className="card" onSubmit={formik.handleSubmit}>
                                    <Card.Body className="p-6">
                                        <Card.Title RootComponent="div">Household Login</Card.Title>
                                        <Form.Group label="TIN Number">
                                            <Form.Input
                                                name="username"
                                                placeholder=""
                                                value={formik.values.username}
                                                onChange={formik.handleChange('username')}
                                                invalid={formik.errors['username'] ? true : false}
                                                feedback={formik.errors['username'] ? formik.errors['username'] : ''}
                                            />
                                        </Form.Group>
                                        <Form.Group label="Password">
                                            <Form.Input
                                                name="password"
                                                type="password"
                                                placeholder=""
                                                value={formik.values.password}
                                                onChange={formik.handleChange('password')}
                                                invalid={formik.errors['password'] ? true : false}
                                                feedback={formik.errors['password'] ? formik.errors['password'] : ''}
                                            />
                                        </Form.Group>
                                        <Form.Footer>
                                            {error ? (<Alert type="danger">
                                                Invalid Credentials
                                            </Alert>) : null}
                                            <Button type="submit" color="primary" block={true}>
                                                Login
                                            </Button>
                                            <div className="mt-2 text-center">
                                                <Link to="/forgotpassword">Forgot Password</Link>
                                            </div>
                                            <div className="mt-2 text-center">
                                                <Link to="/">Home</Link>
                                            </div>
                                        </Form.Footer>
                                    </Card.Body>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};