import * as React from 'react'
import { useHistory, Link } from "react-router-dom";
import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
} from 'tabler-react'

import { SiteWrapper } from '../../components/SiteWrapper'
import HouseholdService from '../../service/household';
import { useCookies } from 'react-cookie';


const list = [
  {
    key: '1',
    item: [
      { content: 'Settlement Bla Bla' },
      { content: 'John Doe' },
      { content: '' },
      { content: 'Invalid TIN no given' },
      { content: 'Rejected' },
      { content: <a href="/householdview?id=1"><Icon name="edit" /> </a> },
    ],
  },

]

export const HouseholdRejectedPage = (props) => {

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false);

  const [tableData, setTableData] = React.useState([]);
  const [cookies, setCookie] = useCookies(['token']);
  const makeList = (list) => {

    let arr = [];

    for (let i = 0; i < list.length; i++) {

      let element ={
        key: list[i].id,
        item: [
          { content: list[i].settlement_id },
          { content: list[i].first_name + ' ' + list[i].last_name },
          { content: list[i].verification },
          { content: list[i].remark },
          { content: <a href="/householdview?id=1"><Icon name="edit" /> </a> },
        ],
      };

      arr.push(element);
    }

    setTableData(arr);
  };

  const fetchData = async () => {

    setLoading(true);
    setError(false);
    
    try {

      let res = await HouseholdService.getRejected(cookies);

      makeList(res.data);

    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>

        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card title="Rejected Households">

              <Dimmer active={loading} loader>
                <Table
                  responsive
                  className="card-table table-vcenter text-nowrap"
                  headerItems={[
                    { content: 'Settlement Name' },
                    { content: 'Name' },
                    { content: 'Verification' },
                    { content: 'Remarks' },
                    { content: null },
                  ]}
                  bodyItems={tableData}
                />

              </Dimmer>
            </Card>
          </Grid.Col>
        </Grid.Row>

      </Page.Content>
    </SiteWrapper>
  )
}
