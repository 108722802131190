import apisauce from 'apisauce';
import { BASE_URL, TIMEOUT } from './constants';

export default class HouseholdService {

    static async settlements() {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });
        const res = await api.get('/get_settlement_names');

        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            throw new Error(res.originalError.message);
        }

    }

    static async getHousehold(id, token) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+token.token);

        console.log('API Call');
        const res = await api.get('/household/' + id);

        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            console.log(res.originalError);
            throw new Error(res.originalError.message);
        }
    }

    static async getPending(token) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+token.token);

        console.log('API Call');
        const res = await api.get('/householdpending');

        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            console.log(res.originalError);
            throw new Error(res.originalError.message);
        }
    }

    static async getRejected(token) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+token.token);

        console.log('API Call');
        const res = await api.get('/householdreject');

        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            console.log(res.originalError);
            throw new Error(res.originalError.message);
        }
    }
    static async approve(id, json, token) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+token.token);

        console.log('API Call');
        const res = await api.put('/approve_household/' + id, json);
        if (res.ok) {
            return { status: res.status, message: res.data }
        } else {
            console.log(res.originalError);
            throw new Error(res.originalError.message);
        }

    }

    static async post(json) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            // timeout: TIMEOUT
        });

        const res = await api.post('/household_registration', json);
        if (res.ok) {
            return { status: res.status, message: res.data }
        } else {
            throw new Error(res.data.error);
        }

    }

    static async getHouseholdReport(values, token) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+token.token);

        console.log('API Call');
        const res = await api.get('/household_report/?status='+values.status+'&settlement_id='+values.settlement+'&employeement='+values.employeement+'&type_of_house='+values.housetype+'&paying_rent='+values.rent);

        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            console.log(res.originalError);
            throw new Error(res.originalError.message);
        }
    }
}