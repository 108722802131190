import * as React from 'react'
import {
  Site,
  Nav,
  Icon,
  Card,
  Grid,
  List,
  Button,
  RouterContextProvider,
} from 'tabler-react'

import { NavLink, withRouter, useHistory } from 'react-router-dom'

import { IS_DEV } from '../service/constants'



export const LandingPage = (props) => {
  const history = useHistory()

  const navigate = (url) => {
    history.push(url);
  }

  const crispChat = ()=>{
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "71362ca2-21e3-40f0-9e8f-5bf5c378ce19";
        // window.$crisp.push(["set", "user:nickname", [profille.first_name]]);
        // window.$crisp.push(["set", "user:email", [profille.email]]);
        (() => {
            const d = document;
            const s = d.createElement("script");
            s.src = "https://client.crisp.chat/l.js";
            s.async = 1;
            d.getElementsByTagName("body")[0].appendChild(s);
        })();
    }

    React.useEffect(() => {
        crispChat();
    }, []);

  return (
    <div className="vh-100 background">
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="max-950 mx-auto">
              <div className="row">
                <div className="banner"></div>
              </div>
              <div className="text-center mb-6">
                <h2>MySettlement</h2>
              </div>

              <div className="row">
                <div className="col col-login mx-auto">
                  <Card>
                    <Card.Body className="p-6">
                      <Button
                        type="button"
                        color="primary"
                        size="lg"
                        className="mb-4"
                        block={true}
                        icon="grid"
                        onClick={() => navigate('/settlementname')}
                      >
                        Register Settlement
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        size="lg"
                        className="mb-4"
                        block={true}
                        icon="home"
                        onClick={() => navigate('/registerhousehold')}
                      >
                        Register Household
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        block={true}
                        icon="log-in"
                        onClick={() => navigate('/publiclogin')}
                      >
                        Login
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="text-center">
          {IS_DEV?'DEVELOPMENT':''}Copyright © 2020
          <a
            href="https://mobile.digital.gov.fj/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {'  '}
            Ministry of Housing and Community Development - Fiji
            {'  '}
          </a>
          {'  '}All rights reserved.
        </div>
      </div>
    </div>
  )
}
