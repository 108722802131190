import React from 'react';


import { Form, Card, Button } from 'tabler-react';

import { Formik, useFormik, useFormikContext } from "formik";
import * as yup from 'yup';

import '../../styles/style.css';
import '../../styles/helper.css';

import Logo from '../../images/logo.svg';

const validationSchema = yup.object({
    currentPassword: yup.string()
        .trim()
        .required('Password cannot be blank'),
    password: yup.string()
        .trim()
        .required('Password cannot be blank')
});


export const StaffChangePasswordPage = () => {

    const submit = (values) =>{
        console.log(values);
    };

    const formik = useFormik({
        initialValues: { current: '', password: '' },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: submit,
    });


    return (

        <div className="page">
            <div className="page-single">
                <div className="container">
                    <div className="row">
                        <div className="col col-login mx-auto">
                            <Form className="card" onSubmit={formik.handleSubmit}>
                                <Card.Body className="p-6">
                                    <Card.Title RootComponent="div">Staff Login - Change Password</Card.Title>
                                    <Form.Group label="Current Password">
                                        <Form.Input
                                            name="password"
                                            type="password"
                                            placeholder=""
                                            value={formik.values.password}
                                            onChange={formik.handleChange('currentPassword')}
                                            invalid={formik.errors['currentPassword'] ? true : false}
                                            feedback={formik.errors['currentPassword'] ? formik.errors['currentPassword'] : ''}
                                        />
                                    </Form.Group>
                                    <Form.Group label="Password">
                                        <Form.Input
                                            name="password"
                                            type="password"
                                            placeholder=""
                                            value={formik.values.password}
                                            onChange={formik.handleChange('password')}
                                            invalid={formik.errors['password'] ? true : false}
                                            feedback={formik.errors['password'] ? formik.errors['password'] : ''}
                                        />
                                    </Form.Group>
                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            Change Password
                                        </Button>
                                    </Form.Footer>
                                </Card.Body>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};