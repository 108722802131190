import * as React from 'react'
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";

import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
  Form,
} from 'tabler-react'

import { PublicSiteWrapper } from '../../components/PublicSiteWrapper'

import { EmployeementOptions, HouseTypeOptions } from '../Questions/Selections'

import { FacilitiesCheckboxes } from '../Questions/Checkboxes'

import { Formik, useFormik, useFormikContext } from "formik";
import FamilyMemberService from '../../service/familymember';
import { parse, format } from 'date-fns'
import * as yup from 'yup';

let today = new Date();
today.setDate(new Date().getDate() - 1);

const validationSchema = yup.object({
    firstName: yup.string().trim().required('First Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed fot this field"),
    lastName: yup.string().trim().required('Last Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed fot this field"),
    relationship: yup.string().trim().required('Relationship is required'),
    dob: yup.date().required('DOB is required').max(today, "Invalid date").typeError('Invalid date'),
    // mobileNumber: yup.string().trim().required('Mobile No is required'),
    email: yup.string().trim().email('Must be a valid email').required('Email is required'),
    // idNo: yup.string().trim().required('TIN No is required'),
    // employeement: yup.string().trim().required('Employeement is required'),
    // income: yup.string().trim().required('Weekly Income is required'),
    // photoId: yup.string().trim().required('Photo ID is required')
});


export const FamilyMemberPage = (props) => {

  const {location} = props;
  console.log(location.state);
  const history = useHistory()
  const navigate = (url) => {
    history.push(url);
  }

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  let finalModel = {};
  const PrepareData = async (model) => {

    finalModel = {};

    finalModel = {
        first_name: model.firstName,
        last_name: model.lastName,
        relationship: model.relationship,
        dob: model.dob,
        mobile_no: model.mobileNumber,
        email: model.email,
        tin_no: model.idNo,
        employeement: model.employeement,
        weekly_income: model.income,
        photo_id: model.photoId,
        house_hold_id: location.state.household
    };
};


  const submit = async (values) => {

    await PrepareData(values);

    try {
        setLoading(true);
        let res = await FamilyMemberService.post(finalModel);
        setLoading(false);
        if (location.state.total == location.state.current){
            history.push('/done?m=H', {model:{...values}});
        }else{
            history.replace('/familymember', {model:{...values}, current:location.state.current+1, total: location.state.total, household: location.state.household});
        formik.resetForm();
        }
      } catch (error) {
          setLoading(false);
          setError(error.message);
      }
    
  };

  const [dobValue, setDobValue] = React.useState('');

  const dobChange = (event) => {

    let value = event.currentTarget.value;

    setDobValue(value);

    let dob = parse(value, 'dd/MM/yyyy', new Date());

    if (String(dob) === "Invalid Date") {

      formik.setFieldValue('dob', '', false);

    } else {
      const dobiso = format(dob, 'yyyy-MM-dd');
      formik.setFieldValue('dob', dobiso, false);
    }
  }

  const upload = (e) => {

    var reader = new FileReader();
    var file = e.target.files[0];

    reader.readAsDataURL(file);

    reader.onload = function () {

      console.log(reader.result);

      formik.setFieldValue('photoId', reader.result, false);

    };

  };

  const formik = useFormik({
    initialValues: {firstName: '',lastName:'', relationship: '', dob:'', mobileNumber: '', email: '', idNo: '', photoId: '', income: 0},
    validationSchema: validationSchema,
    validateOnChange: true,
    // onSubmit: values => {
    //   console.log(values);
    // },
    onSubmit:submit
  });

  const currentYear = new Date().getFullYear()

  return (
    <PublicSiteWrapper>
      <div className="max-950 mx-auto">
      <form onSubmit={formik.handleSubmit}>
        <Page.Card
          title="Register Household"
          RootComponent={Form}
          footer={
            <Card.Footer>
              <div className="d-flex">
                 <Button onClick={() => history.goBack()}>Back</Button>
                {/* <Button onClick={() => navigate('/done?m=H')} color="primary" className="ml-auto"> */}
                <Button type="submit" color="primary" className="ml-auto">
                  Submit
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <h5>{location.state.current} of {location.state.total} Family Member Details</h5>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-6 ml-4 mr-4"
                label="First Name"
                isRequired
              >
                <Form.Input 
                    name="firstName"
                    placeholder=""
                    value={formik.values.firstName}
                    onChange={formik.handleChange('firstName')}
                    invalid={formik.errors['firstName']}
                    feedback={formik.errors['firstName']}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-6 ml-4 mr-4"
                label="Last Name"
                isRequired
              >
                <Form.Input 
                    name="lastName"
                    placeholder=""
                    value={formik.values.lastName}
                    onChange={formik.handleChange('lastName')}
                    invalid={formik.errors['lastName']}
                    feedback={formik.errors['lastName']}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-6 ml-4 mr-4"
                label="Relationship"
                isRequired
              >
                <Form.Select
                    name="relationship"
                    placeholder=""
                    value={formik.values.relationship}
                    onChange={formik.handleChange('relationship')}
                    invalid={formik.errors['relationship']}
                    feedback={formik.errors['relationship']}
                >
                  <option value="">Select</option>
                  <option value="WF">Wife</option>
                  <option value="HB">Husband</option>
                  <option value="DP">Defacto partner</option>
                  <option value="DT">Daughter</option>
                  <option value="SN">Son</option>
                  <option value="GN">Grandson</option>
                  <option value="GD">Granddaughter</option>
                  <option value="FT">Father</option>
                  <option value="MT">Mother</option>
                  <option value="BR">Brother</option>
                  <option value="ST">Sister</option>
                  <option value="ML">Mother in law</option>
                  <option value="FL">Father in law</option>
                  <option value="DL">Daughter in law</option>
                  <option value="SL">Son in law</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-6 ml-4 mr-4"
                label="Date of Birth"
                isRequired
              >
                <Form.MaskedInput
                      placeholder="dd/mm/yyyy"
                      mask={[/\d/, /\d/,  "/", /\d/, /\d/, "/", /\d/, /\d/,/\d/, /\d/]}
                      value={dobValue}
                      onChange={dobChange}
                      invalid={formik.errors['dob']}
                      feedback={formik.errors['dob']}
                    />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <>
            <Grid.Row>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-6 ml-4 mr-4" label="Mobile Number">
                  <Form.Input
                    name="mobileNumber"
                    placeholder=""
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange('mobileNumber')}
                    invalid={formik.errors['mobileNumber']}
                    feedback={formik.errors['mobileNumber']}
                    maxLength={7}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-6 ml-4 mr-4" label="Email">
                  <Form.Input
                    name="email"
                    placeholder=""
                    value={formik.values.email}
                    onChange={formik.handleChange('email')}
                    invalid={formik.errors['email']}
                    feedback={formik.errors['email']}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-6 ml-4 mr-4" label="TIN Number">
                  <Form.Input
                    name="idNo"
                    placeholder=""
                    value={formik.values.idNo}
                    onChange={formik.handleChange('idNo')}
                    invalid={formik.errors['idNo']}
                    feedback={formik.errors['idNo']}
                    maxLength={9}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group
                  className="mb-6 ml-4 mr-4"
                  label="Employeement"
                  isRequired
                >
                  {/* <EmployeementOptions /> */}
                <Form.Select name="employeement"
                    placeholder=""
                    value={formik.values.employeement}
                    onChange={formik.handleChange('employeement')}
                    invalid={formik.errors['employeement']}
                    feedback={formik.errors['employeement']}
                    >
                    <option value="">Select</option>
                    <option value="FT">Full time</option>
                    <option value="PT">Part time</option>
                    <option value="CS">Casual</option>
                    <option value="SE">Self employeed</option>
                    <option value="HW">House worker</option>
                    <option value="UE">Unemployeed</option>
                </Form.Select>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-6 ml-4 mr-4" label="Weekly Income">
                  <Form.Input 
                    name="income"
                    type="number"
                    placeholder=""
                    value={formik.values.income}
                    onChange={formik.handleChange('income')}
                    invalid={formik.errors['income']}
                    feedback={formik.errors['income']}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group className="mb-6 ml-4 mr-4" label="Photo ID">
                <Form.FileInput
                    name="photoId"
                    invalid={formik.errors['photoId']}
                    feedback={formik.errors['photoId']}
                    onChange={upload} />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </>
        </Page.Card>
        </form>
      </div>
    </PublicSiteWrapper>
  )
}
