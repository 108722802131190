import * as React from 'react'
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory,
} from 'react-router-dom'
import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
  Form
} from 'tabler-react'

import { SiteWrapper } from '../../components/SiteWrapper'
import HouseholdService from '../../service/household';
import SettlementService from '../../service/settlement';

import { Formik, useFormik, useFormikContext } from "formik";
import { useCookies } from 'react-cookie';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const tableHeader = [
  { content: 'Settlement Name' },
  { content: 'First Name' },
  { content: 'Last Name' },
  { content: 'DOB' },
  { content: 'Mobile' },
  { content: 'Email' },
  { content: 'TIN Number' },
  { content: 'Employeement' },
  { content: 'Weekly Income' },
  { content: 'Status' },
  { content: null },
];


export const HouseholdListPage = (props) => {
  let query = useQuery();
  let mode = query.get("m");

  const [error, setError] = React.useState(false);


  const history = useHistory()
  const navigate = (url) => {
    history.push(url)
  }

  const [loading, setLoading] = React.useState(false)

  const [tableData, setTableData] = React.useState([]);
  const [cookies, setCookie] = useCookies(['token']);
  const makeSHouseholdList = (list) => {

    let arr = [];

    for (let i = 0; i < list.length; i++) {

      let element = {
        key: list[i].id,
        item: [
          { content: list[i].settlement_id },
          { content: list[i].first_name },
          { content: list[i].last_name },
          { content: list[i].dob },
          { content: list[i].mobile_no },
          { content: list[i].email },
          { content: list[i].tin_no },
          { content: list[i].employeement },
          { content: list[i].weekly_income },
          { content: list[i].status },
        //   { content: '2020-05-30' },
        //   { content: <Link to={'/householdapprove?id=' + list[i].id}> <Icon name="edit" /></Link> }
        ],
      };

      arr.push(element);
    }

    setTableData(arr);
  };

  const [settlementList, setSettlementList] = React.useState([]);

  const loadSettlementList = async () => {

    setLoading(true);
    

    try {
      let res = await SettlementService.settlements(cookies);
      if (res.status != 200) {

      } else {
        setSettlementList(res.data);
      }

      setLoading(false);

    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    loadSettlementList();
  }, []);

  const renderOptions = (item, index) => (

    <option key={index} value={item.id}>{"(" + item.division + ") " + item.name}</option>
  );


  const queryData = (values) => {
    console.log(values);
    fetchData(values);
    // alert("Query");
  };

  const exportExcel = () => {

  };

  const fetchData = async (values) => {

    setLoading(true);
    setError(false);
    try {

      let res = await HouseholdService.getHouseholdReport(values, cookies);

    //   makeList(res.data);
    makeSHouseholdList(res.data);

    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <SiteWrapper>
      <Page.Content title="Household Database">
        <Dimmer active={loading} loader>

          <Grid.Row cards={true}>
            <Grid.Col width={12}>
              <Card title="Filter Options">

                <Formik initialValues={{ division: '', category: '', tenure: '', status: '' }}
                  onSubmit={queryData}>
                  {(props) => (
                    <>
                      <Card.Body>

                        <Grid.Row>
                          <Grid.Col width={4}>
                            <Form.Group className="mb-2 ml-1 mr-1" label="Settlement">
                            <Form.Select
                                name="settlement"
                                placeholder=""
                                // value={formik.values.settlement}
                                onChange={(e) => { props.setFieldValue('settlement', e.target.value, false) }}
                                >
                                <option value="">Select Settlement</option>
                                {settlementList.map(renderOptions)}
                            </Form.Select>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>
                            <Form.Group className="mb-2 ml-1 mr-1" label="Employeement">
                              <Form.Select onChange={(e) => { props.setFieldValue('employeement', e.target.value, false) }}>
                                <option value="">All</option>
                                <option value="FT">Full time</option>
                                <option value="PT">Part time</option>
                                <option value="CS">Casual</option>
                                <option value="SE">Self employeed</option>
                                <option value="HW">House worker</option>
                                <option value="UE">Unemployeed</option>
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>
                            <Form.Group className="mb-2 ml-1 mr-1" label="House Type">
                              <Form.Select onChange={(e) => { props.setFieldValue('housetype', e.target.value, false) }}>
                                <option value="">All</option>
                                <option value="WT">Mostly Wooden and Tin</option>
                                <option value="MT">Mostly Tin</option>
                                <option value="BT">Mostly Block walls and Tin</option>
                                <option value="CO">Concrete</option>
                                <option value="OT">Other</option>
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>

                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Col width={4}>
                            <Form.Group className="mb-2 ml-1 mr-1" label="Rent">
                              <Form.Select onChange={(e) => { props.setFieldValue('rent', e.target.value, false) }}>
                                <option value="">All</option>
                                <option value="IT">ITLTB</option>
                                <option value="LO">Land owner</option>
                                <option value="LL">Landlord</option>
                                <option value="OT">Other</option>
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>
                            <Form.Group className="mb-2 ml-1 mr-1" label="Status">
                              <Form.Select onChange={(e) => { props.setFieldValue('status', e.target.value, false) }}>
                                <option value="">All</option>
                                <option value="confirm">Active</option>
                                <option value="pending">Pending</option>
                                <option value="rejected">Rejected</option>
                                
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>

                          <Grid.Col width={4}>

                          </Grid.Col>

                        </Grid.Row>


                      </Card.Body>
                      <Card.Footer>
                        <div className="d-flex">
                          <Button className="ml-auto" color="primary" type="button" onClick={props.handleSubmit} >
                            Query Data
                          </Button>
                        </div>
                      </Card.Footer>
                    </>
                  )}
                </Formik>

              </Card>
            </Grid.Col>
          </Grid.Row>


          <Grid.Row cards={true}>
            <Grid.Col width={12}>
              <Card title="Household List">

                <Card.Header>
                  <Card.Title>Household Members</Card.Title>
                  <Card.Options>
                    <Button
                      RootComponent="a"
                      color="secondary"
                      size="sm"
                      className="ml-2"
                      onClick={exportExcel}
                    >
                      Export
                  </Button>
                  </Card.Options>
                </Card.Header>

                <Card.Body>


                  <Table
                    responsive
                    className="card-table table-vcenter text-nowrap"
                    headerItems={tableHeader}
                    bodyItems={tableData}
                  />

                </Card.Body>

              </Card>
            </Grid.Col>
          </Grid.Row>
        </Dimmer>
      </Page.Content>
    </SiteWrapper>
  )
}
