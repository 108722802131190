import * as React from 'react'

import { Form } from 'tabler-react'

export const EmployeementOptions = (props) => {
  const { name, ...InputProps } = props

  const onChnage = (value) => {
    console.log('sfgs')
  }

  return (
    <Form.Select name={name} {...InputProps}>
      <option value="">Select</option>
      <option value="FT">Full time</option>
      <option value="PT">Part time</option>
      <option value="CS">Casual</option>
      <option value="SE">Self employed</option>
      <option value="HW">House worker</option>
      <option value="UE">Unemployed</option>
    </Form.Select>
  )
}

export const HouseTypeOptions = (props) => {
  const { name, ...InputProps } = props

  const onChnage = (value) => {
    console.log('sfgs')
  }

  return (
    <Form.Select name={name} {...InputProps}>
      <option value="">Select</option>
      <option value="WT">Mostly Wooden and Tin</option>
      <option value="MT">Mostly Tin</option>
      <option value="BT">Mostly Block walls and Tin</option>
        <option value="CO">Concrete</option>
      <option value="OT">Other</option>
    </Form.Select>
  )
}
