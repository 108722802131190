import * as React from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";

import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
  Form,
} from 'tabler-react';
import { Formik, useFormik, useFormikContext } from "formik";
import * as yup from 'yup';
import { SiteWrapper } from '../../components/SiteWrapper';
import SettlementService from '../../service/settlement';
import { useCookies } from 'react-cookie';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const validationSchema = yup.object({

  remark: yup.string().trim().required('Remarks are required'),
  category: yup.string().trim().required('Category is required'),
  verification: yup.array().required('Select an option'),

});

export const SettlementViewPage = (props) => {

  const history = useHistory();

  const navigate = (url) => {
    history.push(url);
  }

  let query = useQuery();
  let settlementId = query.get("id");


  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const [settlement, setSettlement] = React.useState({});
  const [registrant, setRegistrant] = React.useState({});
  const [cookies, setCookie] = useCookies(['token']);
  const fetchData = async () => {

    setLoading(true);
    setError(false);
    
    try {

      let res = await SettlementService.getSettlement(settlementId, cookies);
      for (let i = 0; i < 3; i++) {

        if (res.data.committee_members[i].is_registrant) {

          setRegistrant(res.data.committee_members[i]);

          res.data.committee_members.splice(i, 1);

          break;
        }
      }
      setSettlement(res.data);
    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    console.log('mounted');
    fetchData();
  }, []);


  const findAccessTo = (value) => {

    if (settlement && settlement.access_to) {
      if (settlement.access_to.find(e => e == value)) {
        return true;
      }
      else {
        return false;
      }
    }
  };

  const findRisk = (value) => {

    if (settlement && settlement.risk) {
      if (settlement.risk.find(e => e == value)) {
        return true;
      }
      else {
        return false;
      }
    }
  };

  const findVerification = (value) => {

    if (settlement && settlement.verification) {
      if (settlement.verification.find(e => e == value)) {
        return true;
      }
      else {
        return false;
      }
    }
  };

  const submit = async (values) => {

    console.log("sublit");
  };

  return (
    <SiteWrapper>
      <Page.Card
        title='View Settlement'
        footer={
          <Card.Footer>
            <div className="d-flex">
              <Button RootComponent="a" href="/home" >Back</Button>
            </div>
          </Card.Footer>
        }
      >

        {loading ? (<Alert type="primary" hasExtraSpace>
          <strong>Loading... Please wait.</strong>
        </Alert>) : null}
        {error ? (<Alert type="danger" hasExtraSpace>
          <strong>Error: </strong> {error}.
        </Alert>) : null}

        <Dimmer active={loading} loader>
          <h5>Settlement Details</h5>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Settlement Name">
                <Form.Input disabled value={settlement.name} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Division">
                <Form.Select disabled value={settlement.division}>
                  <option value="CN">Central</option>
                  <option value="ES">Eastern</option>
                  <option value="WS">Western</option>
                  <option value="NO">Northen</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-4 ml-4 mr-4"
                label="Nearest City/ Town Council/ DO"
              >
                <Form.Input disabled placeholder="" value={settlement.nearest_city} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-4 ml-4 mr-4"
                label="How old is the Settlement (Years)"
              >
                <Form.Select disabled value={settlement.no_of_years} >
                  <option value="LTFV">Less than 5 years</option>
                  <option value="FTTN">5 - 10 years</option>
                  <option value="TTFT">10 -15 years</option>
                  <option value="MTFT">More than 15 years</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Land Tenure">
                <Form.Select disabled value={settlement.land_tenure} >
                  <option value="SL">State Land</option>
                  <option value="IT">iTaukei</option>
                  <option value="FH">Freehold</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-4 ml-4 mr-4"
                label="Number of houses in the settlement?"
              >
                <Form.Input disabled
                  placeholder=""
                  guide={false}
                  mask={[/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                  value={settlement.houses_count}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-4 ml-4 mr-4"
                label="No of people living in the settlement"
              >
                <Form.MaskedInput disabled
                  placeholder=""
                  guide={false}
                  mask={[/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                  value={settlement.no_of_peoples}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-4 ml-4 mr-4"
                label="Nearest Police Station"
              >
                <Form.Input disabled placeholder="" value={settlement.police_station} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-4 ml-4 mr-4"
                label="Distance to nearest police station(Km)"
              >
                <Form.Input disabled placeholder="" value={settlement.how_far} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Access to Facilities">
                <Form.Checkbox disabled
                  label="Road"
                  name="access_to"
                  value="RD"
                  checked={findAccessTo("RD")} />
                <Form.Checkbox disabled
                  label="Electricity"
                  name="access_to"
                  value="EC"
                  checked={findAccessTo("EC")} 
                />
                <Form.Checkbox disabled
                  label="Water"
                  name="access_to"
                  value="WT"
                  checked={findAccessTo("WT")} />
                <Form.Checkbox disabled
                  label="Garbage Collection"
                  name="access_to"
                  value="GC"
                  checked={findAccessTo("GC")}
                />
                <Form.Checkbox disabled
                  label="None"
                  name="access_to"
                  value="NO"
                  checked={findAccessTo("NO")}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-4 ml-4 mr-4"
                label="Any frequent natural disaster/climate change risks?"
              >
                <Form.Checkbox disabled
                  label="Flood"
                  name="risks"
                  value="FL"
                  checked={findRisk("FL")} />
                <Form.Checkbox disabled
                  label="Sea/River encroachment"
                  name="risks"
                  value="SL"
                  checked={findRisk("SL")}
                />
                <Form.Checkbox disabled
                  label="Drought"
                  name="risks"
                  value="DR"
                  checked={findRisk("DR")} />
                <Form.Checkbox disabled
                  label="Earth slip"
                  name="risks"
                  value="ES"
                  checked={findRisk("ES")}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-4 ml-4 mr-4"
                label="Type of houses in the settlement"
              >
                <Form.Select disabled value={settlement.house_type}>
                  <option value="WT">Mostly Wooden and Tin</option>
                  <option value="MT">Mostly Tin</option>
                  <option value="BT">Mostly Block walles and Tin</option>
                  <option value="OT">Other</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <h5>Registant details</h5>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="First Name">
                <Form.Input disabled
                  value={registrant.first_name} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Last Name">
                <Form.Input disabled
                  value={registrant.last_name} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Mobile Number">
                <Form.Input disabled value={registrant.mobile_no} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Email">
                <Form.Input disabled
                  value={registrant.email}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="TIN Number">
                <Form.Input disabled name="example-text-input" placeholder="123456789" value={registrant.tin_no} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Photo ID">
                <img src={registrant.photo_id} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <h5 className="mt-2">Committee Member One Details</h5>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-3" label="First Name">
                <Form.Input disabled value={settlement.committee_members && settlement.committee_members[0].first_name} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-3 mr-4" label="Last Name">
                <Form.Input disabled value={settlement.committee_members && settlement.committee_members[0].last_name} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Mobile Number">
                <Form.Input disabled value={settlement.committee_members && settlement.committee_members[0].mobile_no} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="TIN Number">
                <Form.Input disabled value={settlement.committee_members && settlement.committee_members[0].tin_no} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <h5 className="mt-2">Committee Member Two Details</h5>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-3" label="First Name">
                <Form.Input disabled value={settlement.committee_members && settlement.committee_members[1].first_name} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-3 mr-4" label="Last Name">
                <Form.Input disabled value={settlement.committee_members && settlement.committee_members[1].last_name} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Mobile Number">
                <Form.Input disabled value={settlement.committee_members && settlement.committee_members[1].mobile_no} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="TIN Number">
                <Form.Input disabled value={settlement.committee_members && settlement.committee_members[1].tin_no} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <hr />
          <h4>Staff Verification</h4>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Category">
                <Form.Select disabled value={settlement.category}>
                  <option value="">Select</option>
                  <option value="IS">Informal Settlement</option>
                  <option value="HH">HART Homes</option>
                  <option value="PF">PRB Flats</option>
                  <option value="OT">Other</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Verification">
                <Form.Checkbox disabled
                  isInline
                  label="Local Government"
                  name="Verification"
                  value="LG"
                  checked={findVerification("LG")}
                />
                <Form.Checkbox disabled
                  isInline
                  label="Committee Members"
                  name="Verification"
                  value="CM"
                  checked={findVerification("CM")}
                />
                <Form.Checkbox disabled 
                isInline 
                label="LAV"
                name="Verification"
                value="LA"
                checked={findVerification("LA")}/>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Remarks">
                <Form.Textarea disabled value={settlement.remark} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Status">
                <Form.Select disabled value={settlement.status}>
                  <option value="pending">Pending Approval</option>
                  <option value="confirmed">Approved</option>
                  <option value="rejected">Rejected</option>
                  <option value="inactive">Deleted</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            </Grid.Row>
        </Dimmer>
      </Page.Card>
    </SiteWrapper>
  )
}