import apisauce from 'apisauce';
import { BASE_URL, TIMEOUT } from './constants';

export default class FamilyMemberService {

    static async post(json) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: TIMEOUT
        });

        // api.setHeader('Authorization', 'Token '+token.token);
        const res = await api.post('/family_member_registration', json);
        if (res.ok) {
            return { status: res.status, message: res.data }
        } else {
            throw new Error(res.data.error);
        }

    }

}