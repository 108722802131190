import apisauce from 'apisauce';
import { BASE_URL, TIMEOUT } from './constants';

export default class ProfileService {

    static async getsetProfile(token) {
        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+token.pubtoken);
        const res = await api.get('/user_profile',{})
        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            if (res.status == 403) {
                throw new Error('Invalid credentials.');
            } else {
                console.log(res.originalError);
                console.log(res.data);
                throw new Error(res.originalError.message);
            }
        }
    }
};

