import * as React from 'react'

import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useHistory
} from "react-router-dom";

import {
    Page,
    Avatar,
    Icon,
    Grid,
    Card,
    Text,
    Table,
    Alert,
    Progress,
    colors,
    Dropdown,
    Button,
    StampCard,
    StatsCard,
    ProgressCard,
    Badge,
    Loader,
    Dimmer,
    Content,
    Form,
} from 'tabler-react'

import {PublicSiteWrapper} from '../../components/PublicSiteWrapper'

import {EmployeementOptions, HouseTypeOptions} from '../Questions/Selections'

import {FacilitiesCheckboxes} from '../Questions/Checkboxes'

import {Formik, useFormik, useFormikContext} from "formik";

import HouseholdService from '../../service/household';
import SettlementService from '../../service/settlement';

import { parse, format } from 'date-fns'
import * as yup from 'yup';

let today = new Date();
today.setDate(new Date().getDate() - 1);

const validationSchema = yup.object({
    // settlement_id: yup.string().trim().required('Settlement is required'),
    title: yup.string().trim().required('Title is required'),
    firstName: yup.string().trim().required('First Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed fot this field"),
    lastName: yup.string().trim().required('Last Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed fot this field"),
    dob: yup.date().required('DOB is required').max(today, "Invalid date").typeError('Invalid date'),
    mobileNo: yup.string().trim().required('Mobile No is required'),
    email: yup.string().trim().email('Must be a valid email').required('Email is required'),
    tinNo: yup.string().trim().required('TIN No is required'),
    employeement: yup.string().trim().required('Employeement is required'),
    income: yup.number('invalid decimal', value => (value + "").match(/^\d*\.{1}\d*$/),).required('Weekly Income is required'),
    rent: yup.string().trim().required('Rent is required'),
    accessTo: yup.string().trim().required('Access To is required'),
    houseType: yup.string().trim().required('House Type is required'),
    memberCount: yup.number('Invalid number'),
    photoId: yup.string().trim().required('Photo ID is required')
});

export const RegisterHouseholdPage = (props) => {

    const history = useHistory()
    const navigate = (url) => {
        history.push(url);
    }

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    let finalModel = {};
    const PrepareData = async (model) => {

        finalModel = {};

        finalModel = {
            settlement_id: model.settlement,
            title: model.title,
            first_name: model.firstName,
            last_name: model.lastName,
            dob: model.dob,
            mobile_no: model.mobileNo,
            email: model.email,
            tin_no: model.tinNo,
            employeement: model.employeement,
            weekly_income: model.income,
            paying_rent: model.rent,
            access_to_facilities: model.accessTo,
            type_of_house: model.houseType,
            members_count: model.memberCount,
            photo_id: model.photoId
        };
    };

    const submit = async (values) => {
        await PrepareData(values);
        try {
            setLoading(true);
            let res = await HouseholdService.post(finalModel);
            setLoading(false);
            const toltal = Number(values.memberCount)
            if (toltal == 0) {
                history.push('/done?m=H', {model: {...values}, household: false});
            } else {
                history.push('/familymember', {
                    model: {...values},
                    current: 1,
                    total: toltal,
                    household: res.message.household
                });
            }
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }


    };

    const upload = (e) => {

        var reader = new FileReader();
        var file = e.target.files[0];

        reader.readAsDataURL(file);

        reader.onload = function () {

            formik.setFieldValue('photoId', reader.result, false);

        };

    };

    const formik = useFormik({
        initialValues: {
            settlement: '',
            title: '',
            firstName: '',
            lastName: '',
            mobileNo: '',
            dob: '',
            email: '',
            tinNo: '',
            employeement: '',
            income: '',
            rent: '',
            memberCount: 0,
            accessTo: '',
            houseType: '',
            photoId: ''
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        // onSubmit: values => {
        //   console.log(values);
        // },
        onSubmit: submit
    });

    const currentYear = new Date().getFullYear();

    const [settlementList, setSettlementList] = React.useState([]);

    const loadSettlementList = async () => {

        setLoading(true);

        try {
            let res = await SettlementService.settlements();
            if (res.status != 200) {

            } else {
                setSettlementList(res.data);
            }

            setLoading(false);

        } catch (error) {
            setError(error.message);
            console.log(error);

        }

        setLoading(false);
    };


    React.useEffect(() => {
        loadSettlementList();
    }, []);


    const [dobValue, setDobValue] = React.useState('');

    const dobChange = (event) => {

        let value = event.currentTarget.value;

        setDobValue(value);

        let dob = parse(value, 'dd/MM/yyyy', new Date());

        if (String(dob) === "Invalid Date") {
            formik.setFieldValue('dob', '', false);

        } else {
            const dobiso = format(dob, 'yyyy-MM-dd');
            formik.setFieldValue('dob', dobiso, false);
        }
    }

    const renderOptions = (item, index) => (

        <option key={index} value={item.id}>{"(" + item.division + ") " + item.name}</option>
    );

    return (
        <PublicSiteWrapper>


            <div className="max-950 mx-auto">
                <form onSubmit={formik.handleSubmit}>
                    <Page.Card
                        title="Register Household"
                        footer={
                            <Card.Footer>
                                <div className="d-flex">
                                    <Button onClick={() => history.goBack()}>Back</Button>
                                    <Button type="submit" color="primary" className="ml-auto">
                                        Next
                                    </Button>
                                </div>
                            </Card.Footer>
                        }
                    >
                        {loading ? (<Alert type="primary" hasExtraSpace>
                            <strong>Loading... Please wait.</strong>
                        </Alert>) : null}
                        {error ? (<Alert type="danger" hasExtraSpace>
                            <strong>Error: </strong> {error}.
                        </Alert>) : null}

                        <Dimmer active={loading} loader>

                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-6 ml-4 mr-4"
                                        label="Settlement"
                                        isRequired
                                    >
                                        <Form.Select
                                            name="settlement"
                                            placeholder=""
                                            value={formik.values.settlement}
                                            onChange={formik.handleChange('settlement')}
                                            invalid={formik.errors['settlement']}
                                            feedback={formik.errors['settlement']}
                                        >
                                            <option value="">Select Settlement</option>
                                            {settlementList.map(renderOptions)}
                                        </Form.Select>
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Col xs={6} sm={6} md={6} lg={6}>
                                    <Form.Group className="mb-6 ml-4 mr-4" label="Title" isRequired>
                                        <Form.Select
                                            name="title"
                                            placeholder=""
                                            value={formik.values.title}
                                            onChange={formik.handleChange('title')}
                                            invalid={formik.errors['title']}
                                            feedback={formik.errors['title']}
                                        >
                                            <option value="">Select</option>
                                            <option value="MS">Ms</option>
                                            <option value="MR">Mr</option>
                                            <option value="MI">Miss</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-6 ml-4 mr-4"
                                        label="First Name"
                                        isRequired
                                    >
                                        <Form.Input
                                            name="firstName"
                                            placeholder=""
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange('firstName')}
                                            invalid={formik.errors['firstName']}
                                            feedback={formik.errors['firstName']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group className="mb-6 ml-4 mr-4" label="Last Name">
                                        <Form.Input
                                            name="lastName"
                                            placeholder=""
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange('lastName')}
                                            invalid={formik.errors['lastName']}
                                            feedback={formik.errors['lastName']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group className="mb-6 ml-4 mr-4" label="Date of Birth">
                                        <Form.MaskedInput
                                            placeholder="dd/mm/yyyy"
                                            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/,]}
                                            value={dobValue}
                                            onChange={dobChange}
                                            invalid={formik.errors['dob']}
                                            feedback={formik.errors['dob']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group className="mb-6 ml-4 mr-4" label="Mobile Number">
                                        <Form.Input
                                            name="mobileNo"
                                            placeholder="8951325"
                                            value={formik.values.mobileNo}
                                            onChange={formik.handleChange('mobileNo')}
                                            invalid={formik.errors['mobileNo']}
                                            feedback={formik.errors['mobileNo']}
                                            maxLength={7}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group className="mb-6 ml-4 mr-4" label="Email">
                                        <Form.Input
                                            name="email"
                                            placeholder=""
                                            value={formik.values.email}
                                            onChange={formik.handleChange('email')}
                                            invalid={formik.errors['email']}
                                            feedback={formik.errors['email']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group className="mb-6 ml-4 mr-4" label="TIN Number">
                                        <Form.Input
                                            name="tinNo"
                                            placeholder="123456789"
                                            value={formik.values.tinNo}
                                            onChange={formik.handleChange('tinNo')}
                                            invalid={formik.errors['tinNo']}
                                            feedback={formik.errors['tinNo']}
                                            maxLength={9}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group className="mb-6 ml-4 mr-4" label="Employment">
                                        {/* <EmployeementOptions /> */}
                                        <Form.Select
                                            name="employeement"
                                            placeholder=""
                                            value={formik.values.employeement}
                                            onChange={formik.handleChange('employeement')}
                                            invalid={formik.errors['employeement']}
                                            feedback={formik.errors['employeement']}
                                        >
                                            <option value="">Select</option>
                                            <option value="FT">Full time</option>
                                            <option value="PT">Part time</option>
                                            <option value="CS">Casual</option>
                                            <option value="SE">Self employed</option>
                                            <option value="HW">House worker</option>
                                            <option value="UE">Unemployed</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group className="mb-6 ml-4 mr-4" label="Weekly Income $">
                                        <Form.Input
                                            name="income"
                                            placeholder="8951325"
                                            value={formik.values.income}
                                            onChange={formik.handleChange('income')}
                                            invalid={formik.errors['income']}
                                            feedback={formik.errors['income']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-6 ml-4 mr-4"
                                        label="Are you paying a rent"
                                    >
                                        <Form.Select
                                            name="rent"
                                            placeholder="8951325"
                                            value={formik.values.rent}
                                            onChange={formik.handleChange('rent')}
                                            invalid={formik.errors['rent']}
                                            feedback={formik.errors['rent']}
                                        >
                                            <option value="">Select</option>
                                            <option value="IT">ITLTB</option>
                                            <option value="LO">Land owner</option>
                                            <option value="LL">Landlord</option>
                                            <option value="OT">Other</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-6 ml-4 mr-4"
                                        label="How many additional household members(excluding yourself)"
                                    >
                                        <Form.MaskedInput
                                            name="memberCount"
                                            placeholder="00"
                                            value={formik.values.memberCount}
                                            onChange={formik.handleChange('memberCount')}
                                            invalid={formik.errors['memberCount']}
                                            feedback={formik.errors['memberCount']}
                                            guide={false}
                                            mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group className="mb-6 ml-4 mr-4" label="Type of the House Structure">
                                        {/* <HouseTypeOptions/> */}
                                        <Form.Select
                                            name="houseType"
                                            placeholder=""
                                            value={formik.values.houseType}
                                            onChange={formik.handleChange('houseType')}
                                            invalid={formik.errors['houseType']}
                                        >
                                            <option value="WT">Mostly Wooden and Tin</option>
                                            <option value="MT">Mostly Tin</option>
                                            <option value="BT">Mostly Block walls and Tin</option>
                                            <option value="CO">Concrete</option>
                                            <option value="OT">Other</option>

                                        </Form.Select>
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-6 ml-4 mr-4"
                                        label="Access to facilities"
                                    >
                                        {/* <FacilitiesCheckboxes value={formik.values.accessTo}/> */}

                                        <Form.Checkbox
                                            label="Road"
                                            name="example-radios"
                                            value="RD"
                                            onChange={formik.handleChange('accessTo')}
                                            invalid={formik.errors['accessTo']}
                                        />
                                        <Form.Checkbox
                                            label="Electricity"
                                            name="example-radios"
                                            value="EC"
                                            onChange={formik.handleChange('accessTo')}
                                            invalid={formik.errors['accessTo']}
                                        />
                                        <Form.Checkbox
                                            label="Water"
                                            name="example-radios"
                                            value="WT"
                                            onChange={formik.handleChange('accessTo')}
                                            invalid={formik.errors['accessTo']}
                                        />
                                        <Form.Checkbox
                                            label="Garbage Collection"
                                            name="example-radios2"
                                            value="GC"
                                            onChange={formik.handleChange('accessTo')}
                                            invalid={formik.errors['accessTo']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group className="mb-6 ml-4 mr-4" label="Photo ID">
                                        <Form.FileInput
                                            name="photoId"
                                            invalid={formik.errors['photoId']}
                                            onChange={upload}
                                            feedback={formik.errors['photoId']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                        </Dimmer>
                    </Page.Card>
                </form>
            </div>
        </PublicSiteWrapper>
    )
}
