import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
} from 'react-router-dom'
import 'tabler-react/dist/Tabler.css'



import { LoginPage } from './pages/Login'
import { StaffLoginPage } from './pages/StaffPortal/Login'
import { PublicLoginPage } from './pages/PublicPortal/Login'
import { StaffChangePasswordPage } from './pages/StaffPortal/ChangePassword'
import { StaffForgetPasswordPage } from './pages/StaffPortal/ForgetPassword'
import { HomePage } from './pages/Home'
import { RegisterSettlementPage } from './pages/Settlement/RegisterSettlement'
import { SettlementDetailsPage } from './pages/Settlement/SettlementDetails'
import { DonePage } from './pages/Settlement/Done'
import { RegisterHouseholdPage } from './pages/Household/RegisterHousehold'
import { FamilyMemberPage } from './pages/Household/FamilyMember'
import { SettlementNamePage } from './pages/Settlement/SettlementName'

import { SettlementListPage } from './pages/StaffPortal/SettlementList'
import { SettlementPendingPage } from './pages/StaffPortal/SettlementPending'
import { SettlementRejectedPage } from './pages/StaffPortal/SettlementRejected'
import { SettlementApprovePage } from './pages/StaffPortal/SettlementApprove'
import { SettlementViewPage } from './pages/StaffPortal/SettlementView'
import { HouseholdListPage } from './pages/StaffPortal/HouseholdList'
import { HouseholdViewPage } from './pages/StaffPortal/HouseholdView'
import { HouseholdApprovePage } from './pages/StaffPortal/HouseholdApprove'
import { HouseholdPendingPage } from './pages/StaffPortal/HouseholdPending'
import { HouseholdRejectedPage } from './pages/StaffPortal/HouseholdRejected'
import { LandingPage } from './pages/Index'

import { ProfilePage } from './pages/PublicPortal/Profile'

import { useCookies } from 'react-cookie';

const ProtectedRoute = ({ component: Component, ...rest }) => {

    const [cookie, setCookie] = useCookies(['token']);

    let isAuthed = false;

    if (cookie.hasOwnProperty('token')) {
        isAuthed = true;
    }

    return (
        <Route {...rest} render={(props) => (
            isAuthed ?
                <Component {...props} /> : <Redirect to={{ pathname: '/stafflogin' }} />
        )} />
    )
};

const ProfileRoute = ({ component: Component, ...rest }) => {

    const [cookie, setCookie] = useCookies(['pubtoken']);

    let isAuthed = false;

    if (cookie.hasOwnProperty('pubtoken')) {
        isAuthed = true;
    }

    return (
        <Route {...rest} render={(props) => (
            isAuthed ?
                <Component {...props} /> : <Redirect to={{ pathname: '/publiclogin' }} />
        )} />
    )
};

// const NotFound = () => (
//   <div>
//     <h1>404 - Not Found!</h1>
//     <Link to="/">
//       Go Home
//     </Link>
//   </div>
// );


const App = () => {
    return (
        <Router>

            <ProfileRoute path="/profile" exact component={ProfilePage} />
            <Route path="/publiclogin" exact component={PublicLoginPage} />

            <Route
                path="/settlementname"
                exact
                component={SettlementNamePage}
            />

            <Route
                path="/registersettlement"
                exact
                component={RegisterSettlementPage}
            />
            <Route
                path="/settlementdetails"
                exact
                component={SettlementDetailsPage}
            />
            <Route
                path="/registerhousehold"
                exact
                component={RegisterHouseholdPage}
            />
            <Route
                path="/familymember"
                exact
                component={FamilyMemberPage}
            />
            <Route
                path="/done"
                exact
                component={DonePage}
            />

            <ProtectedRoute path="/home" exact component={HomePage} />
            <ProtectedRoute path="/settlementlist" exact component={SettlementListPage} />
            <ProtectedRoute path="/settlementpending" exact component={SettlementPendingPage} />
            <ProtectedRoute path="/settlementreject" exact component={SettlementRejectedPage} />
            <ProtectedRoute path="/settlementview" exact component={SettlementViewPage} />
            <ProtectedRoute path="/settlementapprove" exact component={SettlementApprovePage} />
            <ProtectedRoute path="/householdlist" exact component={HouseholdListPage} />
            <ProtectedRoute path="/householdview" exact component={HouseholdViewPage} />
            <ProtectedRoute path="/householdapprove" exact component={HouseholdApprovePage} />
            <ProtectedRoute path="/householdpending" exact component={HouseholdPendingPage} />
            <ProtectedRoute path="/householdreject" exact component={HouseholdRejectedPage} />
            <Route path="/" exact component={LandingPage} />
            <Route path="/stafflogin" exact component={StaffLoginPage} />
            <Route path="/changepassword" exact component={StaffChangePasswordPage} />
            <Route path="/forgotpassword" exact component={StaffForgetPasswordPage} />

            {/*<Route component={NotFound} />*/}
        </Router>
    )
}

function Home() {
    return <h2>Home</h2>
}

function About() {
    return <h2>About</h2>
}

function Users() {
    return <h2>Users</h2>
}
export default App
