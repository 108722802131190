import apisauce from 'apisauce';
import { BASE_URL, TIMEOUT } from './constants';

export default class AuthenticationService {

    static async login(username, password) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: TIMEOUT
        });

        const res = await api.post('/login', { username: username, password: password })
        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            if (res.status == 403) {
                throw new Error('Invalid credentials.');
            } else {
                console.log(res.originalError);
                console.log(res.data);
                throw new Error(res.originalError.message);
            }
        }
    }

    static async officerlogin(username, password){
        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: TIMEOUT
        });

        const res = await api.post('/officerlogin', { username: username, password: password })
        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            if (res.status == 403) {
                throw new Error('Invalid credentials.');
            } else {
                console.log(res.originalError);
                console.log(res.data);
                throw new Error(res.originalError.message);
            }
        }
    }

    static async logout(token) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+ token)

        const res = await api.post('/logout')
        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            if (res.status == 403) {
                throw new Error('Invalid credentials.');
            } else {
                console.log(res.originalError);
                console.log(res.data);
                throw new Error(res.originalError.message);
            }
        }
    }

    static async checkToken(token) {

        let api = apisauce.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: TIMEOUT
        });

        api.setHeader('Authorization', 'Token '+ token)

        const res = await api.post('/check_token')
        if (res.ok) {
            return { status: res.status, data: res.data }
        } else {
            if (res.status == 401) {
                throw new Error('Token expired.');
            } else {
                console.log(res.originalError);
                console.log(res.data);
                throw new Error(res.originalError.message);
            }
        }
    }

};

