import React from 'react';


import { Form, Card, Button } from 'tabler-react';

import { Formik, useFormik, useFormikContext } from "formik";
import * as yup from 'yup';

import '../../styles/style.css';
import '../../styles/helper.css';

import Logo from '../../images/logo.svg';

const validationSchema = yup.object({
    username: yup.string()
        .trim()
        .required('Email is required'),
    password: yup.string()
        .trim()
        .required('Password cannot be blank')
});


export const StaffForgetPasswordPage = () => {

    const submit = (values) =>{
        console.log(values);
    };

    const formik = useFormik({
        initialValues: { username: '', password: '' },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: submit,
    });


    return (

        <div className="page">
            <div className="page-single">
                <div className="container">
                    <div className="row">
                        <div className="col col-login mx-auto">
                            <div className="text-center mb-6">
                                <img src={Logo} className="h-8" alt="logo" />
                                <h4 className="mt-4">MySettlement</h4>
                            </div>
                            <Form className="card" onSubmit={formik.handleSubmit}>
                                <Card.Body className="p-6">
                                    <Card.Title RootComponent="div">Staff Login - Forgot Password</Card.Title>
                                    <p className="text-muted">
                                    Enter your email address and your password will be reset and emailed to you.
                                    </p>

                                    <Form.Group label="Email">
                                        <Form.Input
                                            name="username"
                                            placeholder=""
                                            value={formik.values.username}
                                            onChange={formik.handleChange('username')}
                                            invalid={formik.errors['username'] ? true : false}
                                            feedback={formik.errors['username'] ? formik.errors['username'] : ''}
                                        />
                                    </Form.Group>

                                    <Form.Footer>
                                        <Button type="submit" color="primary" block={true}>
                                            Request Password Change
                                        </Button>
                                    </Form.Footer>
                                </Card.Body>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};