import * as React from 'react'
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";
import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
  Form,
} from 'tabler-react'

import { PublicSiteWrapper } from '../../components/PublicSiteWrapper'
import DoneIcon from '../../images/check-circle.svg'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export const DonePage = (props) => {

  let query = useQuery();

  // A= Pending Approval, V = View,
  let mode = query.get("m");


  return (
    <PublicSiteWrapper>
      <div className="max-950 mx-auto">
        <Page.Card
          title={mode=='S'? "Register Settlement": "Household Registration"}
          RootComponent={Grid.Row}
          // footer={
          //   <Card.Footer>
          //     <div className="d-flex">
          //       <Button link>Back</Button>
          //       <Button type="submit" color="primary" className="ml-auto">
          //         Next
          //       </Button>
          //     </div>
          //   </Card.Footer>
          // }
        >
          <Grid.Row>
            <img src={DoneIcon} className="done-check mx-auto" />
          </Grid.Row>
          <Grid.Row>
            <Text className="done-text mx-auto"> Done</Text>
          </Grid.Row>
          <Grid.Row>
            {mode=='S'?(<Text className="done-desc mt-4 mx-auto">
              Thank you, your settlement registration has been successfully
              submitted.
            </Text>):(<Text className="done-desc mt-4 mx-auto">
              Thank you, your household registration has been successfully
              submitted.
            </Text>)}
          </Grid.Row>
          <Grid.Row>
          {mode=='S'?(<Text className="done-desc mt-4 mx-auto">
              Ministry will review the application and contact you soon to
              complete the registration.{'\n'}
              You will be informed via email about the status.
            </Text>):(<Text className="done-desc mt-4 mx-auto">
              Ministry will review the details and send login information.
            </Text>)}
          </Grid.Row>
          <Grid.Row>
            <Button RootComponent="a" href="/" color="primary" className="mt-6 mx-auto">
              Home
            </Button>
          </Grid.Row>
        </Page.Card>
      </div>
    </PublicSiteWrapper>
  )
}
