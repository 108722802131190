import React from 'react';

import {
    useHistory,
    Link,
} from "react-router-dom";
import { Form, Card, Text, Alert, Button } from 'tabler-react';

import { Formik, useFormik, useFormikContext } from "formik";
import * as yup from 'yup';
import { useCookies } from 'react-cookie';

import '../../styles/style.css';
import '../../styles/helper.css';

import Logo from '../../images/logo.svg';

import AuthenticationService from '../../service/authentication';

const validationSchema = yup.object({
    username: yup.string()
        .trim()
        .required('Email is required'),
    password: yup.string()
        .trim()
        .required('Password cannot be blank')
});


export const StaffLoginPage = () => {

    const history = useHistory();

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [loading, setLoading] = React.useState(false);


    const [error, setError] = React.useState(false);



    const submit = async (values) => {

        try {

            let res = await AuthenticationService.officerlogin(values.username, values.password);

            let token = res.data.token;

            setCookie('token', token, { path: '/' });
            localStorage.setItem('username', values.username)
            history.push('/home');

        } catch (error) {

            console.log(error);
            setError(true);
        }

        history.replace('/home', { token: token });
    };

    const formik = useFormik({
        initialValues: { username: '', password: '' },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: submit,
    });

    React.useEffect(() => {
        removeCookie('token');
        localStorage.clear();
    }, []);

    return (
        <div className="vh-100 background">
            <div className="page">
                <div className="page-single">
                    <div className="container">
                        <div className="row">
                            <div className="col col-login mx-auto">
                                <div className="text-center mb-6">
                                    <img src={Logo} className="h-8" alt="logo" />
                                    <h4 className="mt-4">MySettlement</h4>
                                </div>
                                <Form className="card" onSubmit={formik.handleSubmit}>
                                    <Card.Body className="p-6">
                                        <Card.Title RootComponent="div">Staff Login</Card.Title>
                                        <Form.Group label="Email">
                                            <Form.Input
                                                name="username"
                                                placeholder=""
                                                value={formik.values.username}
                                                onChange={formik.handleChange('username')}
                                                invalid={formik.errors['username'] ? true : false}
                                                feedback={formik.errors['username'] ? formik.errors['username'] : ''}
                                            />
                                        </Form.Group>
                                        <Form.Group label="Password">
                                            <Form.Input
                                                name="password"
                                                type="password"
                                                placeholder=""
                                                value={formik.values.password}
                                                onChange={formik.handleChange('password')}
                                                invalid={formik.errors['password'] ? true : false}
                                                feedback={formik.errors['password'] ? formik.errors['password'] : ''}
                                            />
                                        </Form.Group>
                                        <Form.Footer>

                                            {error ? (<Alert type="danger">
                                                Invalid Credentials
                                            </Alert>) : null}
                                            <Button type="submit" color="primary" block={true}>
                                                Login
                                            </Button>
                                            <div className="mt-2 text-center">
                                                <Link to="/forgotpassword">Forgot Password</Link>
                                            </div>
                                        </Form.Footer>
                                    </Card.Body>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};