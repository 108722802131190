import * as React from 'react'
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useHistory
} from "react-router-dom";
import {
    Page,
    Avatar,
    Icon,
    Grid,
    Card,
    Text,
    Table,
    Alert,
    Progress,
    colors,
    Dropdown,
    Button,
    StampCard,
    StatsCard,
    ProgressCard,
    Badge,
    Loader,
    Dimmer,
    Content,
    Form,
} from 'tabler-react'

import {Formik, useFormik, useFormikContext} from "formik";
// import AsyncStorage from '@react-native-community/async-storage';

import {PublicSiteWrapper} from '../../components/PublicSiteWrapper'

import SettlementService from '../../service/settlement';

import * as yup from 'yup';

const validationSchema = yup.object({
    division: yup.string().trim().required('Division is required'),
    nearestCity: yup.string().trim().required('Nearest City is required'),
    tenure: yup.string().trim().required('Tenure is required'),
    age: yup.string().required('Age is required'),
    noHouses: yup.number('Invalid number').required('No Of Houses is required'),
    noPeople: yup.number('Invalid number').required('No of people is required'),
    accessTo: yup.string().trim().required('Access To is required'),
    nearestPolice: yup.string().trim().required('Nearest Police is required'),
    policeDistance: yup.number('is-decimal', 'invalid decimal', value => (value + "").match(/^\d*\.{1}\d*$/),).required('Distance To Nearest Police is required'),
    frequestIssues: yup.string().trim().required('Frequest Issues are required'),
    houseType: yup.string().trim().required('House Type is required')
});


export const SettlementDetailsPage = (props) => {

    const {location} = props;

//   console.log(location.state.model);

    const [error, setError] = React.useState(false);

    const history = useHistory();
    const navigate = (url) => {
        history.push(url);
    }

    const readImage = async (key) => {
        if (key == '') {
            return '';
        }
        try {
            const imageData = await AsyncStorage.getItem(key);
            return imageData;
        } catch (error) {
            console.log(error);
        }

        return '';
    };

    const [loading, setLoading] = React.useState(false);
    let finalModel = {};
    const PrepareData = async (model) => {

        finalModel.registrant = {};
        finalModel.settlement = {};
        finalModel.members = [];

        finalModel.registrant = {
            first_name: model.firstName,
            last_name: model.lastName,
            mobile_no: model.mobileNo,
            email: model.email,
            tin_no: model.tinNo,
            photo_id: model.photoId,
            is_registrant: true
        };

        finalModel.settlement = {
            name: model.settlementName,
            division: model.division,
            nearest_city: model.nearestCity,
            land_tenure: model.tenure,
            no_of_years: model.age,
            houses_count: model.noHouses,
            no_of_peoples: model.noPeople,
            access_to: model.accessTo,
            house_type: model.houseType,
            police_station: model.nearestPolice,
            how_far: model.policeDistance,
            risk: model.frequestIssues,
        };

        finalModel.members.push({
            first_name: model.ConeFirstName,
            last_name: model.ConeLastName,
            mobile_no: model.ConeMobileNo,
            tin_no: model.ConeTinNo,
            is_registrant: false
        });

        finalModel.members.push({
            first_name: model.CtwoFirstName,
            last_name: model.CtwoLastName,
            mobile_no: model.CtwoMobileNo,
            tin_no: model.CtwoTinNo,
            is_registrant: false
        });

        // if (model.photoId !== undefined) {
        //     console.log('Reading image');
        //     const img = await readImage(model.photoId);
        //     finalModel.registrant.photo_id = img;
        // }
    };

    const submit = async (values) => {

        // console.log(values);

        let tmpModel = {...location.state.model, ...values};

        await PrepareData(tmpModel);

        try {

            let res = await SettlementService.post(finalModel);
            setLoading(false);
            history.push('/done');
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }

    };


    const formik = useFormik({
        initialValues: {
            division: '',
            nearestCity: '',
            tenure: '',
            age: '',
            noHouses: '',
            noPeople: '',
            accessTo: '',
            houseType: '',
            nearestPolice: '',
            policeDistance: '',
            frequestIssues: ''
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: submit
    });

    const callSubmit = async (e, status) => {
        // formik.setFieldValue('name', name, false);
        // formik.setFieldValue('status', status, false);

        formik.handleSubmit(e);
    };

    return (
        <PublicSiteWrapper>
            <div className="max-950 mx-auto">
                <form onSubmit={formik.handleSubmit}>
                    <Page.Card
                        title="Register Settlement/Village"
                        footer={
                            <Card.Footer>
                                <div className="d-flex">
                                    <Button link>Back</Button>
                                    {/* <Button onClick={() => navigate('/done?m=S')} color="primary" className="ml-auto"> */}
                                    <Button onClick={e => callSubmit(e)} color="primary" className="ml-auto">
                                        Submit
                                    </Button>
                                </div>
                            </Card.Footer>
                        }
                    >
                        {loading ? (<Alert type="primary" hasExtraSpace>
                            <strong>Loading... Please wait.</strong>
                        </Alert>) : null}
                        {error ? (<Alert type="danger" hasExtraSpace>
                            <strong>Error: </strong> {error}.
                        </Alert>) : null}

                        <Dimmer active={loading} loader>

                            <h5>Settlement Details</h5>
                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group className="mb-4 ml-4 mr-4" label="Division">
                                        <Form.Select
                                            name="division"
                                            placeholder=""
                                            value={formik.values.division}
                                            onChange={formik.handleChange('division')}
                                            invalid={formik.errors['division']}
                                            feedback={formik.errors['division']}
                                        >
                                            <option value="">Select</option>
                                            <option value="CN">Central</option>
                                            <option value="ES">Eastern</option>
                                            <option value="WS">Western</option>
                                            <option value="NT">Northen</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-4 ml-4 mr-4"
                                        label="Nearest City/ Town Council/ DO"
                                    >
                                        <Form.Input
                                            name="nearestCity"
                                            placeholder=""
                                            value={formik.values.nearestCity}
                                            onChange={formik.handleChange('nearestCity')}
                                            invalid={formik.errors['nearestCity']}
                                            feedback={formik.errors['nearestCity']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-4 ml-4 mr-4"
                                        label="How old is the Settlement (Years)"
                                    >
                                        <Form.Select
                                            name="age"
                                            placeholder=""
                                            value={formik.values.age}
                                            onChange={formik.handleChange('age')}
                                            invalid={formik.errors['age']}
                                            feedback={formik.errors['age']}
                                        >
                                            <option value="">Select</option>
                                            <option value="LTFV">Less than 5 years</option>
                                            <option value="FTTN">5 - 10 years</option>
                                            <option value="TTFT">10 -15 years</option>
                                            <option value="MTFT">More than 15 years</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group className="mb-4 ml-4 mr-4" label="Land Tenure">
                                        <Form.Select
                                            name="tenure"
                                            placeholder=""
                                            value={formik.values.tenure}
                                            onChange={formik.handleChange('tenure')}
                                            invalid={formik.errors['tenure']}
                                            feedback={formik.errors['tenure']}
                                        >
                                            <option value="">Select</option>
                                            <option value="ST">State Land</option>
                                            <option value="IT">iTaukei</option>
                                            <option value="FH">Freehold</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-4 ml-4 mr-4"
                                        label="Number of houses in the settlement?"
                                    >
                                        <Form.MaskedInput
                                            name="noHouses"
                                            placeholder=""
                                            value={formik.values.noHouses}
                                            onChange={formik.handleChange('noHouses')}
                                            invalid={formik.errors['noHouses']}
                                            feedback={formik.errors['noHouses']}
                                            guide={false}
                                            mask={[/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}

                                        />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-4 ml-4 mr-4"
                                        label="No of people living in the settlement"
                                    >
                                        <Form.MaskedInput
                                            name="noPeople"
                                            placeholder=""
                                            value={formik.values.noPeople}
                                            onChange={formik.handleChange('noPeople')}
                                            invalid={formik.errors['noPeople']}
                                            feedback={formik.errors['noPeople']}
                                            guide={false}
                                            mask={[/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-4 ml-4 mr-4"
                                        label="Nearest Police Station"
                                    >
                                        <Form.Input
                                            name="nearestPolice"
                                            placeholder=""
                                            value={formik.values.nearestPolice}
                                            onChange={formik.handleChange('nearestPolice')}
                                            invalid={formik.errors['nearestPolice']}
                                            feedback={formik.errors['nearestPolice']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-4 ml-4 mr-4"
                                        label="Distance to nearest police station(Km)"
                                    >
                                        <Form.Input
                                            name="policeDistance"
                                            placeholder=""
                                            value={formik.values.policeDistance}
                                            onChange={formik.handleChange('policeDistance')}
                                            invalid={formik.errors['policeDistance']}
                                            feedback={formik.errors['policeDistance']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-4 ml-4 mr-4"
                                        label="Access to Facilities"
                                    >
                                        <Form.Checkbox
                                            label="Road"
                                            name="accessTo"
                                            value="RD"
                                            onChange={formik.handleChange('accessTo')}
                                            invalid={formik.errors['accessTo']}
                                            feedback={formik.errors['accessTo']}
                                        />
                                        <Form.Checkbox
                                            label="Electricity"
                                            name="accessTo"
                                            value="EC"
                                            onChange={formik.handleChange('accessTo')}
                                            invalid={formik.errors['accessTo']}
                                            feedback={formik.errors['accessTo']}
                                        />
                                        <Form.Checkbox
                                            label="Water"
                                            name="accessTo"
                                            value="WT"
                                            onChange={formik.handleChange('accessTo')}
                                            invalid={formik.errors['accessTo']}
                                            feedback={formik.errors['accessTo']}
                                        />
                                        <Form.Checkbox
                                            label="Garbage Collection"
                                            name="accessTo"
                                            value="GC"
                                            onChange={formik.handleChange('accessTo')}
                                            invalid={formik.errors['accessTo']}
                                            feedback={formik.errors['accessTo']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-4 ml-4 mr-4"
                                        label="Any frequent natural disaster/climate change risks?"
                                    >
                                        <Form.Checkbox
                                            label="Flood"
                                            name="frequestIssues"
                                            value="FL"
                                            onChange={formik.handleChange('frequestIssues')}
                                            invalid={formik.errors['frequestIssues']}
                                            feedback={formik.errors['frequestIssues']}
                                        />
                                        <Form.Checkbox
                                            label="Sea/River encroachment"
                                            name="frequestIssues"
                                            value="SL"
                                            onChange={formik.handleChange('frequestIssues')}
                                            invalid={formik.errors['frequestIssues']}
                                            feedback={formik.errors['frequestIssues']}
                                        />
                                        <Form.Checkbox
                                            label="Drought"
                                            name="frequestIssues"
                                            value="DR"
                                            onChange={formik.handleChange('frequestIssues')}
                                            invalid={formik.errors['frequestIssues']}
                                            feedback={formik.errors['frequestIssues']}
                                        />
                                        <Form.Checkbox
                                            label="Earth slip"
                                            name="frequestIssues"
                                            value="ES"
                                            onChange={formik.handleChange('frequestIssues')}
                                            invalid={formik.errors['frequestIssues']}
                                            feedback={formik.errors['frequestIssues']}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Group
                                        className="mb-4 ml-4 mr-4"
                                        label="Type of houses in the settlement"
                                    >
                                        <Form.Select
                                            name="houseType"
                                            placeholder=""
                                            value={formik.values.houseType}
                                            onChange={formik.handleChange('houseType')}
                                            invalid={formik.errors['houseType']}
                                            feedback={formik.errors['houseType']}
                                        >
                                            <option value="">Select</option>
                                            <option value="WT">Mostly Wooden and Tin</option>
                                            <option value="MT">Mostly Tin</option>
                                            <option value="BT">Mostly Block Wall and Tin</option>
                                            <option value="CO">Concrete</option>
                                            <option value="OT">Other</option>

                                        </Form.Select>
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                        </Dimmer>
                    </Page.Card>
                </form>
            </div>
        </PublicSiteWrapper>
    )
}
