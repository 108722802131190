import * as React from 'react'
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";
import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
  Form
} from 'tabler-react'

import { SiteWrapper } from '../../components/SiteWrapper'
import SettlementService from '../../service/settlement';
import { Formik, useFormik, useFormikContext } from "formik";
import { useCookies } from 'react-cookie';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const tableHeader = [
  { content: 'Settlement Name' },
  { content: 'Division' },
  { content: 'Categoty' },
  { content: 'City' },
  { content: 'Police Station' },
  { content: 'Number of Houses' },
  { content: null },
];

export const SettlementListPage = (props) => {
  let query = useQuery();
  let mode = query.get("m");
  const [error, setError] = React.useState(false);

  const [cookies, setCookie] = useCookies(['token']);

  const history = useHistory()
  const navigate = (url) => {
    history.push(url)
  }


  const [loading, setLoading] = React.useState(false);

  const [tableData, setTableData] = React.useState([]);

  const makeSettlementList = (list) => {

    let arr = [];

    for (let i = 0; i < list.length; i++) {

      let element = {
        key: list[i].id,
        item: [
          { content: list[i].name },
          { content: list[i].division },
          { content: list[i].category },
          { content: list[i].nearest_city },
          { content: list[i].police_station },
          { content: list[i].houses_count },
        //   { content: '2020-05-30' },
        //   { content: <Link to={'/householdapprove?id=' + list[i].id}> <Icon name="edit" /></Link> }
        ],
      };

      arr.push(element);
    }

    setTableData(arr);
  };


  const queryData = (values) => {
    console.log(values);
    // alert("Query");
    fetchData(values);
  };

  const exportExcel = () => {

  };

  const fetchData = async (values) => {

    setLoading(true);
    setError(false);

    try {

      let res = await SettlementService.getSettlementdReport(values, cookies);

    //   makeList(res.data);
    makeSettlementList(res.data);

    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <SiteWrapper>
      <Page.Content title="Settlement Database">
        <Dimmer active={loading} loader>


          <Grid.Row cards={true}>
            <Grid.Col width={12}>
              <Card title="Filter Options">

                <Formik initialValues={{ division: '', category: '', tenure: '', status: '' }}
                  onSubmit={queryData}>
                  {(props) => (
                    <>
                      <Card.Body>

                        <Grid.Row>
                          <Grid.Col width={4}>
                            <Form.Group className="mb-2 ml-1 mr-1" label="Category">
                              <Form.Select onChange={(e) => { props.setFieldValue('category', e.target.value, false) }} >
                                <option value="">All</option>
                                <option value="IS">Informal Settlement</option>
                                <option value="HH">HART Homes</option>
                                <option value="PF">PRB Flats</option>
                                <option value="OT">Other</option>
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>
                            <Form.Group className="mb-2 ml-1 mr-1" label="Division">
                              <Form.Select onChange={(e) => { props.setFieldValue('division', e.target.value, false) }}>
                                <option value="">All</option>
                                <option value="CN">Central</option>
                                <option value="ES">Eastern</option>
                                <option value="WS">Western</option>
                                <option value="NO">Northen</option>
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>
                            <Form.Group className="mb-2 ml-1 mr-1" label="Land Tenure">
                              <Form.Select onChange={(e) => { props.setFieldValue('tenure', e.target.value, false) }}>
                                <option value="">All</option>
                                <option value="ST">State Land</option>
                                <option value="IT">iTaukei</option>
                                <option value="FH">Freehold</option>
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>

                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Col width={4}>
                            <Form.Group className="mb-2 ml-1 mr-1" label="Status">
                              <Form.Select onChange={(e) => { props.setFieldValue('status', e.target.value, false) }}>
                                <option value="">All</option>
                                <option value="confirm">Active</option>
                                <option value="pending">Pending</option>
                                <option value="rejected">Rejected</option>
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col width={4}>

                          </Grid.Col>
                          <Grid.Col width={4}>

                          </Grid.Col>

                        </Grid.Row>


                      </Card.Body>
                      <Card.Footer>
                        <div className="d-flex">
                          <Button className="ml-auto" color="primary" type="button" onClick={props.handleSubmit} >
                            Query Data
                          </Button>
                        </div>
                      </Card.Footer>
                    </>
                  )}
                </Formik>

              </Card>
            </Grid.Col>
          </Grid.Row>



          <Grid.Row cards={true}>
            <Grid.Col width={12}>
              <Card>
                <Card.Header>
                  <Card.Title>Settlements</Card.Title>
                  <Card.Options>
                    <Button
                      RootComponent="a"
                      color="secondary"
                      size="sm"
                      className="ml-2"
                      onClick={exportExcel}
                    >
                      Export
                  </Button>
                  </Card.Options>
                </Card.Header>

                <Card.Body>

                  <Table
                    responsive
                    className="card-table table-vcenter text-nowrap"
                    headerItems={tableHeader}
                    bodyItems={tableData}
                  />
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Dimmer>
      </Page.Content>
    </SiteWrapper>
  )
}
