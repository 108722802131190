import * as React from 'react'
import {
  Site,
  Nav,
  Grid,
  List,
  Button,
  RouterContextProvider,
} from 'tabler-react'
import logo from '../images/logo.svg'
import { NavLink, withRouter, useHistory } from 'react-router-dom'

const navBarItems = [
  {
    value: 'Home',
    to: '/home',
    icon: 'home',
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: 'Settlements',
    icon: 'grid',
    subItems: [
      {
        value: 'Pending Approval',
        to: '/settlementpending',
        LinkComponent: withRouter(NavLink),
      },
      {
        value: 'Rejected',
        to: '/settlementreject',
        LinkComponent: withRouter(NavLink),
      },
    ],
  },
  {
    value: 'Household',
    icon: 'house',
    subItems: [
      {
        value: 'Pending Approval',
        to: '/householdpending',
        LinkComponent: withRouter(NavLink),
      },
      {
        value: 'Rejected',
        to: '/householdreject',
        LinkComponent: withRouter(NavLink),
      },
    ],
  },
  {
    value: 'Reporting',
    icon: 'trello',
    subItems: [
      { value: 'Settlement Database', to: '/settlementlist', LinkComponent: withRouter(NavLink) },
      { value: 'Household Database', to: '/householdlist', LinkComponent: withRouter(NavLink) },
    ],
  }
]
const loggedInUser = localStorage.getItem("username");
const accountDropdownProps = {
  avatarURL: './demo/faces/female/25.jpg',
  name: loggedInUser,
  description: loggedInUser,
  options: [
    { icon: 'settings', value: 'Change Password', to: "/changepassword"},
    { icon: 'log-out', value: 'Sign out' ,to: "/stafflogin" },
  ],
}

export const SiteWrapper = (props) => {
  const { children, staticContext, ...SiteProps } = props

  return (
    <>
      <Site.Wrapper
        {...SiteProps}
        headerProps={{
          href: '/',
          alt: 'Staff Portal - MySettlement',
          imageURL: './images/logo.svg',
          navItems: (
            <Nav.Item type="div" className="d-none d-md-flex">
              <Button
                href="https://app.crisp.chat/website"
                target="_blank"
                outline
                size="sm"
                RootComponent="a"
                color="primary"
              >
                Crisp Chat
              </Button>
            </Nav.Item>
          ),
          accountDropdown: accountDropdownProps,
        }}
        navProps={{ itemsObjects: navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          copyright: (
            <React.Fragment>
              Copyright © 2020
              <a
                href="https://mobile.digital.gov.fj/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {'  '}
                Ministry of Housing and Community Development - Fiji 
                {'  '}
              </a>
              {'  '}All rights reserved.
            </React.Fragment>
          ),
        }}
      >
        {children}
      </Site.Wrapper>
    </>
  )
}
