let api_url ='';
let isDev = false;

if(process.env.NODE_ENV == 'production')
{
    api_url = process.env.PROD_API_URL;
    isDev = false;
}else{
    api_url = process.env.DEBUG_API_URL;
    isDev = true;
}

export const BASE_URL = api_url;
export const TIMEOUT = 15000;
export const IS_DEV = isDev;