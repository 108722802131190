import * as React from 'react'

import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";

import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  StatsCard,
  ProgressCard,
  Badge,
  Loader,
  Dimmer,
  Content,
  Form,
} from 'tabler-react';
import { Formik, useFormik, useFormikContext } from "formik";
import * as yup from 'yup';

import { SiteWrapper } from '../../components/SiteWrapper';
import HouseholdService from '../../service/household';

import { EmployeementOptions, HouseTypeOptions } from '../Questions/Selections';
import { useCookies } from 'react-cookie';

const validationSchema = yup.object({
  remark: yup.string().trim().required('Remarks are required'),
  verification: yup.array().required('Select an option'),

});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const HouseholdApprovePage = (props) => {
  const history = useHistory()

  const navigate = (url) => {
    history.push(url);
  }

  let query = useQuery();
  let householdId = query.get("id");

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const [household, setHousehold] = React.useState({});
  const [tableData, setTableData] = React.useState([]);
  const [cookies, setCookie] = useCookies(['token']);
  const submit = async (values) => {
    

    setLoading(true);
    setError(false);

    try {
      let res = await HouseholdService.approve(householdId, values, cookies);

    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error);
    }
    setLoading(false);

    history.push('/home', { success: 'Household Approval/Rejection Successful.' });

  };

  const formik = useFormik({
    initialValues: { remark: '', verification: [], status: '' },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: submit
  });


  const getRelationship = (value) => {
    switch (value) {

      case 'WF':
        return 'Wife';
        break;
      case 'HB':
        return 'Husband';
        break;
      case 'DP':
        return 'Defacto partner';
        break;
      case 'DT':
        return 'Daughter';
        break;
      case 'SN':
        return 'Son';
        break;
      case 'GN':
        return 'Grandson';
        break;
      case 'GD':
        return 'Granddaughter';

        break;
      case 'ML':
        return 'Mother in law';

        break;
      case 'FL':
        return 'Father in law';

        break;
      case 'DL':
        return 'Daughter in law';

        break;
      case 'SL':
        return 'Son in law';

        break;

      default:
        return '';
    }
  };

  const getEmployeement = (value) => {
    switch (value) {
      case 'FT':
        return 'Full time';
        break;
      case 'PT':
        return 'Part time';
        break;
      case 'CS':
        return 'Casual';
        break;
      case 'SE':
        return 'Self employeed';
        break;

      case 'HW':
        return 'House worker';
        break;
      case 'UE':
        return 'Unemplyeed';
        break;

      default:
        return '';
    }
  };

  const makeList = (list) => {

    let arr = [];

    for (let i = 0; i < list.length; i++) {

      let element = {
        key: list[i].id,
        item: [
          { content: list[i].first_name + ' ' + list[i].last_name },
          { content: getRelationship(list[i].relationship) },
          { content: list[i].dob },
          { content: list[i].mobile_no },
          { content: list[i].tin_no },
          { content: getEmployeement(list[i].employeement) },
          { content: list[i].weekly_income },
        ],
      };
      arr.push(element);
    }

    setTableData(arr);
  };


  const fetchData = async () => {

    setLoading(true);
    setError(false);

    try {

      let res = await HouseholdService.getHousehold(householdId, cookies);

      setHousehold(res.data);
      makeList(res.data.family_members);

    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const findAccessTo = (value) => {

    if (household && household.access_to_facilities) {
      if (household.access_to_facilities.find(e => e == value)) {
        return true;
      }
      else {
        return false;
      }
    }
  };

  const setValidation = (e, value) => {

    let checked = e.currentTarget.checked;

    let arr = formik.values.verification;

    if (checked) {
      arr.push(value);
    } else {

      let i = arr.indexOf(value);
      if (i > -1) {
        arr.splice(i, 1);
      }
    }

    formik.setFieldValue('verification', arr, false);

  };


  const callSubmit = async (e, status) => {

    formik.setFieldValue('status', status, false);
    formik.handleSubmit(e);
  };


  return (
    <SiteWrapper>
      <Page.Card
        title='Approve Household Account'
        footer={
          <Card.Footer>
            <div className="d-flex">
              <Button RootComponent="a" href="/home">Back</Button>
              <Button color="danger"
                type="button" className="ml-auto"
                onClick={e => callSubmit(e, 'rejected')} >
                Reject
              </Button>
              <Button type="button" color="primary" className="ml-5"
                onClick={e => callSubmit(e, 'confirm')} >
                Approve
              </Button>
            </div>
          </Card.Footer>
        }
      >
        {loading ? (<Alert type="primary" hasExtraSpace>
          <strong>Loading... Please wait.</strong>
        </Alert>) : null}
        {error ? (<Alert type="danger" hasExtraSpace>
          <strong>Error: </strong> {error}.
        </Alert>) : null}

        <Dimmer active={loading} loader>

          <h5>Household Details</h5>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-6 ml-4 mr-4"
                label="Settlement">
                <Form.Input disabled value={household.settlement} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={6} sm={6} md={6} lg={6}>
              <Form.Group className="mb-6 ml-4 mr-4" label="Title">
                <Form.Select disabled value={household.title}>
                  <option value="MS">Ms</option>
                  <option value="MR">Mr</option>
                  <option value="MI">Miss</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-6 ml-4 mr-4"
                label="First Name">
                <Form.Input disabled value={household.first_name} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-6 ml-4 mr-4" label="Last Name">
                <Form.Input disabled value={household.last_name} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-6 ml-4 mr-4" label="Date of Birth">
                <Form.Input disabled value={household.dob} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-6 ml-4 mr-4" label="Mobile Number">
                <Form.Input disabled value={household.mobile_no} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-6 ml-4 mr-4" label="Email">
                <Form.Input disabled value={household.email} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-6 ml-4 mr-4" label="TIN Number">
                <Form.Input disabled value={household.tin_no} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-6 ml-4 mr-4" label="Employeement">
                <EmployeementOptions disabled value={household.employeement} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-6 ml-4 mr-4" label="Weekly Income">
                <Form.Input disabled value={household.weekly_income} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-6 ml-4 mr-4"
                label="Are you paying a rent"
              >
                <Form.Select disabled value={household.paying_rent}>
                  <option value="IT">ITLTB</option>
                  <option value="LO">Land owner</option>
                  <option value="LL">Landloard</option>
                  <option value="OT">Other</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group
                className="mb-6 ml-4 mr-4"
                label="Number of members in the house">
                <Form.Input disabled value={household.members_count} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-6 ml-4 mr-4" label="House type">
                <HouseTypeOptions disabled={true} value={household.type_of_house} />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-6 ml-4 mr-4" label="Access to facilities">
                <Form.Checkbox disabled
                  label="Road"
                  name="facilities"
                  value="RD"
                  checked={findAccessTo("RD")}
                />
                <Form.Checkbox disabled
                  label="Electricity"
                  name="facilities"
                  value="EC"
                  checked={findAccessTo("EC")} />
                <Form.Checkbox disabled
                  label="Water"
                  name="facilities"
                  value="WT"
                  checked={findAccessTo("WT")}
                />
                <Form.Checkbox disabled
                  label="Garbage Collection"
                  name="facilities"
                  value="GC"
                  checked={findAccessTo("GC")}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-6 ml-4 mr-4" label="Photo ID">
                <img src={household.photo_id} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <Table
                responsive
                className="card-table table-vcenter text-nowrap"
                headerItems={[
                  { content: 'Name' },
                  { content: 'Relationship' },
                  { content: 'Date of Birth' },
                  { content: 'Mobile Number' },
                  { content: 'TIN Number' },
                  { content: 'Employeement' },
                  { content: 'Weekly Imcome' },
                ]}
                bodyItems={tableData}
              />
            </Grid.Col>
          </Grid.Row>

          <hr />
          <h4>Staff Verification</h4>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Verification">
                <Form.Checkbox
                  isInline
                  label="Local Government"
                  name="Verification"
                  value="LG"
                  onChange={e => setValidation(e, "LG")}
                />
                <Form.Checkbox
                  isInline
                  label="Committee Members"
                  name="Verification"
                  value="CM"
                  onChange={e => setValidation(e, "CM")}
                />
                <Form.Checkbox
                  isInline
                  label="LAV"
                  name="Verification"
                  value="LA"
                  onChange={e => setValidation(e, "LA")}
                />
                {formik.errors['verification'] ? (<Text.Small color='danger' >{formik.errors['verification']}</Text.Small>) : null}
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6}>
              <Form.Group className="mb-4 ml-4 mr-4" label="Remarks" isRequired>
                <Form.Textarea error={formik.errors['remark']} value={formik.values.remark} onChange={formik.handleChange('remark')} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Dimmer>
      </Page.Card>
    </SiteWrapper>
  )
}
